import React, { useEffect, useState } from 'react';
import HeaderBuyer from '../../component/layout/HeaderBuyer';
import FooterSmall from '../../component/layout/FooterSmall';
import Page from '../../component/layout/Page';

import ProductRequirements from '../../component/utility/buyer/ProductRequirements';
import ProductPurchaseDtl from '../../component/utility/buyer/ProductPurchaseDtl';

import { Row, Col, Card, CardBody, } from 'reactstrap';

import axios from 'axios';
import {mainUrl} from '../../MainUrl';
import { notification } from '../../component/hocs/notification';

import {MdLocationOn} from 'react-icons/md';

import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
const optionstack = {
    scales: {
        xAxes: [{
            stacked: true
        }],
        yAxes: [{
            stacked: true
        }]
    }
};
let labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const data1 = {
    labels: labels,
    datasets: [
        // These two will be in the same stack.
        {
            label: 'Sales (In thousand)',
            data: [15, 20, 10, 5, 15, 18, 25, 45, 35, 33, 29],
            backgroundColor: 'rgba(36, 115, 203, 1)',
        },
        {
            label: 'Profit (In thousand)',
            data: [5, 8, 2, 1.5, 5, 8, 10, 15, 12, 11, 10],
            backgroundColor: 'rgba(109, 168, 69, 1)',
        },

    ]
};

const Profile = () => {
    const [userDetail, setUserDetail] = useState( () => JSON.parse(sessionStorage.getItem('user_detail')) );
    
    return (
        <>
            <HeaderBuyer />
            <Page>
                <Row className='p-5'>
                    <Col md="3" className='mb-3'>
                        <div className='profileImage'>
                            <img className='img-fluid rounded-circle' src={require('../../assets/img/buyer.png')} alt="profile" />
                        </div>
                        <div className='profileName mb-3'>
                            {userDetail?.org_name}
                        </div>
                        <div className='profileDtl mb-5'>
                            <div className='d-flex justify-content-between'>
                                <b>Type: </b> 
                                <span>{userDetail?.org_type_desc}</span>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <b>Staff: </b> 
                                <span>{userDetail?.no_staff}</span>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <b>Contact Person: </b> 
                                <span>{userDetail?.name}</span>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <b>Designation: </b> 
                                <span>{userDetail?.designation_desc}</span>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <b>Mobile No.: </b> 
                                <span>+91 {userDetail?.mobile_no}</span>
                            </div>
                            
                        </div>
                        <div className='addressImg mb-3'>
                            <div className='addr_img'>
                                <MdLocationOn size={30} color="#ff1616" />
                            </div>
                            <div>
                                {userDetail?.address_detail?.address}
                            </div>
                        </div>
                    </Col>
                    <Col md="9">
                        <Row className='mb-3'>
                            <ProductRequirements />
                        </Row>
                        
                        <Row>
                            <Col md={{offset: 2, order: 2, size: 8}}>
                                <Card className='mb-3'>
                                    <CardBody className='text-center'>
                                        <h5>Graphical sales Data</h5>
                                        <Bar options={optionstack} data={data1} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Page>
            <FooterSmall />
        </>
    )
}
export default Profile;