import React, {useEffect, useState} from 'react';

import Header from './../component/layout/Header';
import Footer from './../component/layout/Footer';

import {
    Row,
    Col,
    Card,
    CardBody,
    ListGroupItem,
    ListGroup

} from 'reactstrap';
import Testimonial from './Testimonial';

import {FaShoePrints, FaMoneyBillAlt, FaAngleDoubleDown, FaCircle, FaTruck} from 'react-icons/fa';
import {GiReceiveMoney, GiMilkCarton, GiBreakingChain} from 'react-icons/gi';
import {MdGroups} from 'react-icons/md';
import {BsCloudSunFill} from 'react-icons/bs';
import {BiHappy} from 'react-icons/bi';
import Slider from '../component/utility/Slider';


function Home() {
    const [b_1, setB_1] = useState(false);
    const [b_2, setB_2] = useState(false);
    const [b_3, setB_3] = useState(false);

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    return (
        <div>
            <Header />
            
            <div className='borderBottom'></div>

            {/* ----------------- Secreen - 1 ----------------- */}
            

            <Row className=" hometopPage" style={{marginTop:"64px",}}>
                <Col md="12" className="text-center">
                   {/*<video loop="true" autoplay="autoplay" muted id="myVideo" controls="false">
                        <source src={require('../assets/imilk.mp4')} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                    <div style={{zIndex:'999', }}>
                        <img src={require('../assets/img/logo_big.png')} height="320px" alt="img" />
                        <div className='mb-0 mt-0 '>
                                <FaAngleDoubleDown size={45} color="#2473cb" />
                        </div>
                        <h4 className='text-primary'><b>Complete Bulk Milk Sourcing Solution</b></h4>
                    </div>*/}
                    <Slider />
                </Col>
                
            </Row>
            <div className='borderBottom'></div>

            {/* ----------------- Secreen - 2 ----------------- */}

            <Row className="p-5">
                <Col xs="12" className="text-center mb-4 mt-3">
                   <h4 className='text-primary'><b>I-MILK: Complete Bulk Milk Sourcing Solution</b></h4>
                </Col>
                <Col md="4" className="text-center">
                    <Card color={b_3 ? "secondary" : "primary"} className='h-100 cursor' onMouseEnter={() => setB_3(true)} onMouseLeave={() => setB_3(false)}>
                        <img src={require('../assets/img/p4.png')} height="300px" alt="img" /> 
                        <CardBody>
                            <h5 className='text-white mb-0'><b>Hotels, Restaurants, Canteens & other Institutions</b></h5>
                            <small className='text-white'>HoReCa: Hotel, Restaurant and Catering</small>
                            <div className='text-white fs-20'>Directly Source from Farmers</div>
                        </CardBody>
                    </Card>   
                </Col>

                <Col md="4" className="text-center">
                    <Card color={b_2 ? "secondary" : "primary"} className='h-100 cursor' onMouseEnter={() => setB_2(true)} onMouseLeave={() => setB_2(false)}>
                        <img src={require('../assets/img/p3.png')} height="300px" alt="img" /> 
                        <CardBody>
                            <div className='text-white fs-20'>India’s first one-stop
                                solution for Direct Sourcing
                                of Bulk Milk and Milk
                                products from farmers
                            </div>
                        </CardBody>
                    </Card>    
                </Col>
                
                <Col md="4" className="text-center">
                    <Card color={b_1 ? "secondary" : "primary"} className='h-100 cursor' onMouseEnter={() => setB_1(true)} onMouseLeave={() => setB_1(false)}>
                        <img src={require('../assets/img/p2.png')} height="300px" alt="img" />
                        <CardBody>
                            <h5 className='text-white'><b>Farmer</b></h5>
                            <div className='text-white fs-20'>Directly Sell to Bulk Buyers</div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            
            <div className='borderBottom'></div>
            {/* ----------------- Secreen - 3 ----------------- */}

            <Row className="p-5">
                <Col xs="12" className="text-center mb-5 d-flex align-items-center justify-content-center ">
                    <img className='img-fluid me-2' src={require('../assets/img/p7.png')} width="100px" alt="img" /> 
                    <h4 className='text-primary'>
                        <b>I-MILK: Direct Sourcing on App</b>
                        <div className='fs-18'>Benefits to Bulk Buyers: HoReCa</div>
                    </h4>
                </Col>
                <Col md="9">
                    <Row>
                        
                        <Col md="4" className='mb-5'>
                            <div className='pointBox lightBg h-100'>
                                <div className='pointBoxIcon'>
                                    <div className='pointIcon'>
                                        <GiReceiveMoney color="" size={35} />
                                    </div>
                                </div>
                                <h5><b>Cost Savings</b></h5>
                                <div>Lower prices per litre of milk</div>
                            </div>
                        </Col>
                        <Col md="4" className='mb-5'>
                            <div className='pointBox lightBg h-100'>
                                <div className='pointBoxIcon'>
                                    <div className='pointIcon'>
                                        <FaMoneyBillAlt color="" size={35} />
                                    </div>
                                </div>
                                <h5><b>Price Benchmarking</b></h5>
                                <div>Compare the best prices of multiple milk producers and prevailing market price across multiple locations! </div>
                            </div>
                        </Col>
                        <Col md="4" className='mb-5'>
                            <div className='pointBox lightBg h-100'>
                                <div className='pointBoxIcon'>
                                    <div className='pointIcon'>
                                        <FaShoePrints color="" size={35} />
                                    </div>
                                </div>
                                <h5><b>Direct Sourcing</b></h5>
                                <div>High quality fresh produce directly from farm to kitchen</div>
                            </div>
                        </Col>
                        
                    </Row>  
                      
                    <Row>
                    
                        <Col md="4" className='mb-5'>
                            <div className='pointBox lightBg h-100'>
                                <div className='pointBoxIcon'>
                                    <div className='pointIcon'>
                                        <FaTruck color="" size={35} />
                                    </div>
                                </div>
                                <h5><b>On Time Delivery</b></h5>
                                <div>Reliable Daily Delivery, on time, always</div>
                            </div>
                        </Col>
                        <Col md="4" className='mb-5'>
                            <div className='pointBox lightBg h-100'>
                                <div className='pointBoxIcon'>
                                    <div className='pointIcon'>
                                        <BsCloudSunFill color="" size={35} />
                                    </div>
                                </div>
                                <h5><b>AI Enable Forecasting</b></h5>
                                <div>Plan Products for different seasons</div>
                            </div>
                        </Col>
                        <Col md="4" className='mb-5'>
                            <div className='pointBox lightBg h-100'>
                                <div className='pointBoxIcon'>
                                    <div className='pointIcon'>
                                        <BiHappy color="" size={35} />
                                    </div>
                                </div>
                                <h5><b>Consumer Delight</b></h5>
                                <div>Wider choice of product mix from pan India</div>
                            </div>
                        </Col>
                    </Row>    
                    <Row>   
                        <Col md="4" className='mb-5'>
                            <div className='pointBox lightBg h-100'>
                                <div className='pointBoxIcon'>
                                    <div className='pointIcon'>
                                        <GiMilkCarton color="" size={35} />
                                    </div>
                                </div>
                                <h5><b>Traceability of produce</b></h5>
                                <div>Detailed source information and Quality parameters</div>
                            </div>
                        </Col>
                        <Col md="4" className='mb-5'>
                            <div className='pointBox lightBg h-100'>
                                <div className='pointBoxIcon'>
                                    <div className='pointIcon'>
                                        <GiBreakingChain color="" size={35} />
                                    </div>
                                </div>
                                <h5><b>Sustainable Supply Chain</b></h5>
                                <div>Choose from farmers with sustainable practices</div>
                            </div>
                        </Col>
                        <Col md="4" className='mb-5'>
                            <div className='pointBox lightBg h-100'>
                                <div className='pointBoxIcon'>
                                    <div className='pointIcon'>
                                        <MdGroups color="" size={35} />
                                    </div>
                                </div>
                                <h5><b>“Be Vocal for Local”</b></h5>
                                <div>Support Nearby Milk Producers with better reliability of supply chain and reduction in carbon footprint</div>
                            </div>
                        </Col>

                    </Row>

                </Col>
                <Col md="3" className='text-center'>
                    <img className='img-fluid' src={require('../assets/img/p5.png')} alt="img" />
                </Col>  
            </Row>


            <div className='borderBottom'></div>
            {/* ----------------- Secreen - 4 ----------------- */}
            <Row className="p-5">
                <Col xs="12" className="text-center mb-4 mt-3 d-flex align-items-center justify-content-center">
                   <h4 className='text-primary'><b>I-MILK: Driving Farm Sustainability</b></h4>
                </Col>
                <Col md="5">
                    <div className='d-flex justify-content-center align-items-center' style={{marginBottom:"20px"}}>
                        <img className='' width="130px" src={require('../assets/img/guaranteed.png')} alt="img" />
                        <div>
                            <h5>100% Quality Guarantee</h5>
                            <div>Sourced from farmers with Gold Standards in Quality Assurance!</div>
                        </div>
                    </div>
                    <Card color="primary" outline className='mb-3'>
                        <ListGroup flush>
                            <ListGroupItem>
                                <h6 className='m-0'>Get TEST-O-MILK kit free! </h6>
                            </ListGroupItem>
                            <ListGroupItem>
                                <FaCircle className='me-1' color="#ff1616" /> Be assured that there are No Adulterants Urea / Starch / Boric Acid / Soap / Detergent / Neutralizer / Hydrogen Peroxide
                            </ListGroupItem>
                            <ListGroupItem>
                                <FaCircle className='me-1' color="#6da845" /> Microbial quality test of milk
                            </ListGroupItem>
                            <ListGroupItem>
                                <FaCircle className='me-1' color="#f5a700" /> Lactometer Test: Specific Gravity/SNF
                            </ListGroupItem>
                        </ListGroup>  
                        <ListGroup flush>
                            <ListGroupItem>
                                <h6 className='m-0'> Get Dairy Adulteration Testing Kit free!</h6>
                            </ListGroupItem>
                            <ListGroupItem>
                                <FaCircle className='me-1' color="#000000" /> Be assured that there is No Starch / Maida / Atta in Butter, Ghee & Paneer
                            </ListGroupItem>
                        </ListGroup>  
                    </Card>

                </Col>
                <Col md="2" className='d-flex justify-content-center align-items-center'>
                    <img className='img-fluid' src={require('../assets/img/p8.png')} alt="img" />
                </Col>
                <Col md="5">
                    <div className='d-flex justify-content-center align-items-center mt-3 mb-3'>
                        <img className='img-fluid' src={require('../assets/img/dash.png')} alt="img" /> 
                        <div><h5>Dairy Assessment for Sustainable Households</h5></div>
                    </div>
                    <Card color="primary" outline className='mb-3'>
                        
                        <ListGroup flush>
                            <ListGroupItem>
                                <h6 className='m-0'>DASH Framework rates each Household on 15 Sustainability parameters of</h6>
                            </ListGroupItem>
                            <ListGroupItem>
                                <FaCircle className='me-1' color="#ff1616" /> Economic Efficiency
                            </ListGroupItem>
                            <ListGroupItem>
                                <FaCircle className='me-1' color="#6da845" /> Social Security
                            </ListGroupItem>
                            <ListGroupItem>
                                <FaCircle className='me-1' color="#f5a700" /> Ecological Security
                            </ListGroupItem>
                        </ListGroup>  
                    </Card>
                </Col>
            </Row>

            <div className='borderBottom'></div>
            {/* ----------------- Secreen - 5 ----------------- 

            <Row className="p-5">
                <Col xs="12" className="text-center mb-5 d-flex align-items-center justify-content-center">
                    <img src={require('../assets/img/p2.png')} height="100px" alt="img" />
                    <h4 className='text-primary ps-2'>
                        <b>I-MILK: Complete Selling on App</b>
                        <div className='fs-18'>Benefits to Producers: Farmers</div>
                    </h4>
                </Col>
                <Col md="9">
                    <Row>
                        <Col md="4" className='mb-5'>
                            <div className='pointBox lightBg h-100'>
                                <div className='pointBoxIcon'>
                                    <div className='pointIcon'>
                                        <FaHandshake color="" size={35} />
                                    </div>
                                </div>
                                <h5><b>No middlemen</b></h5>
                                <div>Direct trading with multiple institutional buyers</div>
                            </div>
                        </Col>
                        <Col md="4" className='mb-5'>
                            <div className='pointBox lightBg h-100'>
                                <div className='pointBoxIcon'>
                                    <div className='pointIcon'>
                                        <BsCashCoin color="" size={35} />
                                    </div>
                                </div>
                                <h5><b>Transparency in Pricing</b></h5>
                                <div>Currently controlled by unorganized sector</div>
                            </div>
                        </Col>
                        <Col md="4" className='mb-5'>
                            <div className='pointBox lightBg h-100'>
                                <div className='pointBoxIcon'>
                                    <div className='pointIcon'>
                                        <GiTakeMyMoney color="" size={35} />
                                    </div>
                                </div>
                                <h5><b>Higher Prices</b></h5>
                                <div>Better realization per litre of milk for farmers</div>
                            </div>
                        </Col>
                    </Row>    
                    <Row>
                        <Col md="4" className='mb-5'>
                            <div className='pointBox lightBg h-100'>
                                <div className='pointBoxIcon'>
                                    <div className='pointIcon'>
                                        <BsLink color="" size={35} />
                                    </div>
                                </div>
                                <h5><b>Market Linkages</b></h5>
                                <div>Get multiple local buyer options updated regularly</div>
                            </div>
                        </Col>
                        <Col md="4" className='mb-5'>
                            <div className='pointBox lightBg h-100'>
                                <div className='pointBoxIcon'>
                                    <div className='pointIcon'>
                                        <GiMilkCarton color="" size={35} />
                                    </div>
                                </div>
                                <h5><b>Better Quality Practices</b></h5>
                                <div>Alignment with Buyers Quality Parameters</div>
                            </div>
                        </Col>
                        <Col md="4" className='mb-5'>
                            <div className='pointBox lightBg h-100'>
                                <div className='pointBoxIcon'>
                                    <div className='pointIcon'>
                                        <BsEyeFill color="" size={35} />
                                    </div>
                                </div>
                                <h5><b>Regular market insights</b></h5>
                                <div>Price dissemination to plan product and pricing</div>
                            </div>
                        </Col>
                    </Row>  
                    <Row>
                        <Col md="4" className='mb-5'>
                            <div className='pointBox lightBg h-100'>
                                <div className='pointBoxIcon'>
                                    <div className='pointIcon'>
                                        <GiMilkCarton color="" size={35} />
                                    </div>
                                </div>
                                <h5><b>Market driven Product conversion</b></h5>
                                <div>AI driven analytics and forecasting to plan product mix</div>
                            </div>
                        </Col>
                        <Col md="4" className='mb-5'>
                            <div className='pointBox lightBg h-100'>
                                <div className='pointBoxIcon'>
                                    <div className='pointIcon'>
                                        <BiHappy color="" size={35} />
                                    </div>
                                </div>
                                <h5><b>Better Shelf Life</b></h5>
                                <div>Enhancement through treatment and conversion and reduce wastage</div>
                            </div>
                        </Col>
                    </Row>  
                </Col>
                
                <Col md="3">
                    <img className='img-fluid' src={require('../assets/img/p5.png')} alt="img" />
                </Col>  
            </Row>
            <div className='borderBottom'></div>*/}
            {/* ----------------- Secreen - Testimonial ----------------- */}
            <Row>
                <Col xs="12">
                    <video loop="true" autoplay="autoplay" muted id="myVideo" controls="false">
                        <source src={require('../assets/imilk.mp4')} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                </Col>
            </Row>
            <div className='borderBottom'></div>
            <Row>
                <Col xs="12" className="text-center mb-4 mt-3 d-flex align-items-center justify-content-center">
                    <img src={require('../assets/img/p6.png')} height="80px" alt="img" />
                    <h4 className='text-primary ps-3'><b>Satisfied Customers!</b></h4>
                </Col>
                <Testimonial />
            </Row>

            <Footer />
        </div>
    )
}

export default Home;