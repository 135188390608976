import React, { useState } from 'react';

import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,

} from 'reactstrap';

const FarmerCard2 = ({data, ...props}) => {

    return(
        <>
            <Col md={3} className='mb-2'>
                <Card>
                    <CardBody>
                        <CardTitle tag="h5">{data.name}</CardTitle>
                        <div className="d-flex justify-content-between">
                            <div><b>Registration No.:</b></div>
                            <div>{data.farmer_code}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div><b>Animal Count:</b></div>
                            <div>-</div>
                        </div>
                    </CardBody>
                </Card>
            </Col>

        </>
    )
}

export default FarmerCard2;