import React, { useState, useEffect } from 'react';

//import { notification } from '../hocs/notification';
import { Link } from 'react-router-dom';
import Logo from './../../assets/img/logo.jpeg';
import {
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    //DropdownItem,
} from 'reactstrap';

import { GiHamburgerMenu } from 'react-icons/gi';

function Header() {
    const [activeId, setActiveId] = useState("1");
    const [menu, setMenu] = useState(false);
    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const click_handler = (id) => {
        sessionStorage.setItem('currId', id);
        setActiveId(id);
    }

    /*const controlNavbar = () => {
        if (typeof window !== 'undefined') { 
          if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
            setShow(true); 
          }else if (window.scrollY ==  0){
            setShow(false);
          } 
    
          // remember current page location to use in the next move
          setLastScrollY(window.scrollY); 
        }
      };
    
      useEffect(() => {
        if (typeof window !== 'undefined') {
          window.addEventListener('scroll', controlNavbar);
    
          // cleanup function
          return () => {
            window.removeEventListener('scroll', controlNavbar);
          };
        }
      }, [lastScrollY]);*/

      useEffect(() => {
        let curr_id = sessionStorage.getItem('currId');
        if(curr_id != null && curr_id !== undefined){
            setActiveId(curr_id);
        }else{
            setActiveId('1')
        }
      }, [])

    return (
        <>
            { show && 
                <div className='headerPage'>
                    <Row>
                        
                        <Col xs={6} className="p-2">
                            <div className='justify-content-start align-items-center h-100 d-none d-md-flex'>
                                <Link to="/" className={activeId === "1" && 'active'}>
                                    <span onClick={() => click_handler('1')}>HOME</span>
                                </Link>
                                <Link to="/about-us" className={activeId === "2" && 'active'} >
                                    <span onClick={() => click_handler('2')}>ABOUT US</span>
                                </Link>
                                <Link to="/farmer" className={activeId === "3" && 'active'} >
                                    <span onClick={() => click_handler('3')}>FPO</span>
                                </Link>
                                <Link to="/buyer" className={activeId === "4" && 'active'} >
                                    <span onClick={() => click_handler('4')}>BUYER</span>
                                </Link>
                                <Link to="/contact" className={activeId === "5" && 'active'} >
                                    <span onClick={() => click_handler('5')}>CONTACT US</span>
                                </Link>
                            </div>
                            <div className='d-flex d-md-none justify-content-start align-items-center h-100'>
                                
                                <Dropdown isOpen={menu} toggle={()=>setMenu(!menu)}>
                                    <DropdownToggle
                                        data-toggle="dropdown"
                                        tag="span"
                                    >
                                        <GiHamburgerMenu className='ms-4 cursor' color="#2473cb" size={30} />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <div onClick={()=>setMenu(false)}>
                                            <Link to="/">HOME</Link>
                                        </div>
                                        <div onClick={()=>setMenu(false)}>
                                            <Link to="/about-us">ABOUT US</Link>
                                        </div>
                                        <div onClick={()=>setMenu(false)}>
                                            <Link to="/farmer">FPO</Link>
                                        </div>
                                        <div onClick={()=>setMenu(false)}>
                                            <Link to="/buyer">BUYER</Link>
                                        </div>
                                        <div onClick={()=>setMenu(false)}>
                                            <Link to="/contact">CONTACT US</Link>
                                        </div>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <img className='pe-4 ' height="60px" src={Logo} alt="logo" />
                        </Col>
                    </Row>
                </div>
            }
        </>
    )
}

export default Header;