import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import HeaderFarmer from '../../component/layout/HeaderFarmer';
import FooterSmall from '../../component/layout/FooterSmall';
import Page from '../../component/layout/Page';
import FarmerCard2 from '../../component/utility/farmer/FarmerCard2';

import { notification } from '../../component/hocs/notification';

import axios from 'axios';
import {mainUrl} from '../../MainUrl';
import {
    Row,
    Col,
    Card,
    CardBody,
    Button

} from 'reactstrap';

import {FaPlusCircle} from 'react-icons/fa';

const ProductFarmerList = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [farmers, setFarmers] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
        getfarmerlist();
    }, []);

    const getfarmerlist = () => {
        let postData = {"fpo_code": sessionStorage.getItem('user_id'), "prod_id": location.state.prod_id}
        axios({
            method: 'post',
            url: mainUrl + 'user/getFarmerListbyProduct',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data.status === 1){
                   let list = response.data.data.map((row, index) => {
                        return(<FarmerCard2 data={row.detail} key={index} />)
                   });
                   setFarmers(list);
                }else{
                    let notify = notification({ message: response.data[0].message, type: 'error' });
                    notify();
                    setLoading(false);
                }

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    return (
        <div>
            <HeaderFarmer />
            <Page>
                <Row className='p-3'>
                    <Col xs="12">
                        <Card className='mb-2'>
                            <CardBody className='d-flex justify-content-between'>
                                <h5>Farmer List</h5>
                            </CardBody>
                        </Card>
                    </Col>
                    {farmers}
                </Row>
                
            </Page>
            <FooterSmall />
        </div>


    )
}

export default ProductFarmerList;