import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';
import Logo from './../../assets/img/logo.jpeg';
import {
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    //DropdownItem,
} from 'reactstrap';
import Avatar from '@mui/material/Avatar';

function HeaderBuyer() {
    const navigate = useNavigate();

    const [activeId, setActiveId] = useState("11");
    const [menu, setMenu] = useState(false);
    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const click_handler = (id) => {
        sessionStorage.setItem('currId', id);
        setActiveId(id);
    }

    useEffect(() => {
        let curr_id = sessionStorage.getItem('currId');
        if(curr_id != null && curr_id !== undefined){
            setActiveId(curr_id);
        }else{
            setActiveId('1')
        }
        if(sessionStorage.getItem('user_id') === undefined || 
            sessionStorage.getItem('user_id') === null ||
            sessionStorage.getItem('user_id') === ''){
            logout_handler();
        }
    }, [])

    const logout_handler = () => {
    sessionStorage.clear();
    setMenu(false);
    setActiveId('1');
    navigate("/");
    }  

    return (
        <>
            { show && 
                <div className='headerPage'>
                    <Row>
                        
                        <Col xs={2} md={6} className="p-2">
                            <div className='justify-content-start align-items-center h-100 d-none d-md-flex'>
                                {/*<Avatar className="ms-3" style={{backgroundColor: "#2473cb"}}>PS</Avatar>*/}
                                <Avatar alt="img" className="ms-3" src={require('../../assets/img/buyer.png')} />
                                <Link to="/productMapBuyer" className={activeId === "11" && 'active'}>
                                    <span onClick={() => click_handler('11')}>HOME</span>
                                </Link>
                                <Link to="/profile-buyer" className={activeId === "12" && 'active'}>
                                    <span onClick={() => click_handler('12')}>PROFILE</span>
                                </Link>
                                <Link to="/transaction" className={activeId === "13" && 'active'}>
                                    <span onClick={() => click_handler('13')}>TRANSACTION</span>
                                </Link>
                                
                                <a className={activeId === "1" ? 'active cursor' : 'cursor'}>
                                    <span onClick={logout_handler}>LOGOUT</span>
                                </a>
                            </div>
                            <div className='d-flex d-md-none justify-content-start align-items-center h-100'>
                                
                                <Dropdown isOpen={menu} toggle={()=>setMenu(!menu)}>
                                    <DropdownToggle
                                        data-toggle="dropdown"
                                        tag="span"
                                    >
                                        <Avatar alt="img" className="ms-3 cursor" src={require('../../assets/img/buyer.png')} />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <div onClick={()=>setMenu(false)}>
                                            <Link to="/productMapBuyer">HOME</Link>
                                        </div>
                                        <div onClick={()=>setMenu(false)}>
                                            <Link to="/profile-buyer">PROFILE</Link>
                                        </div>
                                        <div onClick={()=>setMenu(false)}>
                                            <Link to="/transaction">TRANSACTION</Link>
                                        </div>
                                        <div className="cursor" onClick={logout_handler}>
                                            <a>LOGOUT</a>
                                        </div>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col xs={10} md={6} className="d-flex align-items-center justify-content-end">
                            <span className='text-primary me-2 pe-2' style={{borderRight: '3px solid #f5a700'}}>{JSON.parse(sessionStorage.getItem('user_detail')).org_name}</span>
                            <span className='text-primary me-2 pe-2' style={{borderRight: '3px solid #f5a700'}}>Buyer</span> 
                            <img className='pe-4 ' height="60px" src={Logo} alt="logo" />
                        </Col>
                    </Row>
                </div>
            }
        </>
    )
}

export default HeaderBuyer;