// Formate Date string "mm/dd/yyyy hh:mm"
export function getDateFormat (inputDate) {
    let dt = new Date (inputDate);

    let cYear = dt.getFullYear();
    let cMonth = (dt.getMonth() + 1).toString().padStart(2, "0");
    let cdate = dt.getDate().toString().padStart(2, "0");
   // formate yyyy-mm-dd
   let send = cYear + "-" + cMonth + "-" + cdate;

   return send;
}

// Get today Date
export function getTodayDate() {
    let dt = new Date();
    let cYear = dt.getFullYear();
    let cMonth = (dt.getMonth() + 1).toString().padStart(2, "0");
    let cdate = dt.getDate().toString().padStart(2, "0");

    // formate yyyy-mm-dd
    let send = cYear + "-" + cMonth + "-" + cdate;

    return send;
}

// Calculate max Date
export function getMaxDate(date, days) {
    let dt = new Date(date);
    let dt1 = dt.setDate(dt.getDate() + days);
    let cYear = dt.getFullYear();
    let cMonth = (dt.getMonth() + 1).toString().padStart(2, "0");
    let cdate = dt.getDate().toString().padStart(2, "0");;

    // formate yyyy-mm-dd
    let send = cYear + "-" + cMonth + "-" + cdate;

    return send;
}