import React, { useEffect, useState } from 'react';
import HeaderBuyer from '../../component/layout/HeaderBuyer';
import { notification } from '../../component/hocs/notification';

import iconMap from '../../assets/img/mapIcon.png';
import mapSelf from '../../assets/img/mapself.png';

import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";


import "@reach/combobox/styles.css";
import MapStyles from "../../component/hocs/MapStyles";

import axios from 'axios';
import {mainUrl} from '../../MainUrl';

import { Row, Col, Button, Card, CardBody, Spinner, Label, Input, Modal, ModalHeader, ModalBody, } from 'reactstrap';
import { FaSearchLocation } from 'react-icons/fa';
import { BsStarFill, BsStarHalf } from 'react-icons/bs';
import Slider from '@mui/material/Slider';

const libraries = ["places"];
const mapContainerStyle = {
  height: "85vh",
  width: "100%",
};
const options = {
  styles: MapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};

const ProductMap = () => {
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState('');
    const [selProduct, setSelProduct] = useState([1]);
    const [quantity, setQuantity] = useState([20, 40]);
    const [mapData, setMapData] = useState('');
    const [mapViewData, setMapViewData] = useState('');

    const [productModal, setProductModal] = useState(false);
    const [selected, setSelected] = React.useState(null);

    const [userDetail, setUserDetail] = useState(()=>{
        JSON.parse(sessionStorage.getItem('user_detail'))
    })

    const center = {
        lat: Number(JSON.parse(sessionStorage.getItem('user_detail')).address_detail.lat),
        lng: Number(JSON.parse(sessionStorage.getItem('user_detail')).address_detail.lng),
      };

    const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyD_ClOiBd3rgMuW_ub4gjwoAhNXtumT5FE",
    libraries,
    });

    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        get_product_list();
        search_handler();
    }, []);

    const MaskCharacter = (str, mask, n = 1) => {
      
        // Slice the string and replace with
        // mask then add remaining string
        return ('' + str).slice(0, -n)
            .replace(/./g, mask)
            + ('' + str).slice(-n);
    }

    const get_product_list = () => {
        axios({
            method: 'post',
            url: mainUrl + 'master/getProduct',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
               let list = response.data.data;
               setProduct(list);
            })
            .catch(error => {
                let notify = notification({ message: "Product list not found", type: 'error' });
                notify();
            })
    }

    const search_handler = () => {
        setLoading(true);
        setMapData('');
        setMapViewData('');
        let lat = JSON.parse(sessionStorage.getItem('user_detail')).address_detail.lat;
        let lng = JSON.parse(sessionStorage.getItem('user_detail')).address_detail.lng;

        if(selProduct.length > 0){
            let prod = JSON.stringify(selProduct);
            prod = prod.replaceAll('","', ',');
            prod = prod.replaceAll('"', '');
            prod = prod.replaceAll('[', '');
            prod = prod.replaceAll(']', '');

            let postData = {"latitude" : lat  ,"longitude": lng, "product": prod}
            axios({
                method: 'post',
                url: mainUrl + 'activity/getfarmerSearch',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    setLoading(false);
                    if(response.data.data.length > 0){

                        let list = response.data.data.map((row, index) => {
                            return(
                                <Marker
                                    key= {index}
                                    position={{ lat: Number(row.lat), lng: Number(row.lng) }}
                                    onClick={() => setSelected(row)}
                                    icon={{
                                        url: iconMap,
                                        origin: new window.google.maps.Point(0, 0),
                                        anchor: new window.google.maps.Point(15, 15),
                                        scaledSize: new window.google.maps.Size(30, 30),
                                    }}
                                />
                            )
                        })
                        setMapData(list);
                        let listView = response.data.data.map((row, index) => {
                            return(
                                <Col md="12" className='mb-3' key={index}>
                                    <Card className='h-100'>
                                        <CardBody>
                                            <Row>
                                                <Col xs="4" className='text-center'>
                                                    <img className='img-fluid' src={require('../../assets/img/user.png')} alt="image" />
                                                    <div>
                                                        <BsStarFill color="#f5a700" />
                                                        <BsStarFill color="#f5a700" />
                                                        <BsStarFill color="#f5a700" />
                                                        <BsStarHalf color="#f5a700" />
                                                    </div>
                                                </Col>
                                                <Col xs="8">
                                                    <h5 className="textBlur"><b>{MaskCharacter(row.fpo_name, '#', 2)}</b></h5>
                                                    <div className='fs-12'><b>Distance: </b>{Number(row.distance).toFixed(2)} km</div>
                                                    <div className='fs-12'><b>Contact: </b> <span className="textBlur">{MaskCharacter(row.mobile_no, '*', 3)}</span> </div>
                                                    <div className='fs-12'><b>Location: </b> <span className="textBlur">{row.address}</span> </div>
                                                    <div className='fs-12'><b>Product: </b>{
                                                        row.products.map((el, index) => {
                                                            return <span key={index}>{el.prod_name}, </span>
                                                        }) 
                                                    } 
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        })
                        setMapViewData(listView);
                    }else{
                        setLoading(false);
                        let notify = notification({ message: "No data found!", type: 'error' });
                        notify();
                    }
                    
                    

                }).catch(error => {
                    console.log(error);
                    let notify = notification({ message: "Failed! Try Again", type: 'error' });
                    notify();
                    setLoading(false);
                })

        }else{
            let notify = notification({ message: "Please select atleast one product.", type: 'warn' });
            notify();
            setLoading(false);
        }
    }

    function valuetext(value) {
        return `${value}kg`;
    }

    const select_product_handler = (id) => {
        let temp = [...selProduct];
        if(temp.includes(id)){
            temp.splice(temp.indexOf(id), 1);
        }else{
            temp.push(id);
        }
        setSelProduct(temp);
    }

    if (loadError) return "Error";
    if (!isLoaded) return "Loading..."; 

    return (
        <div>
            <HeaderBuyer />
            <Row className='p-3 pb-0' style={{ marginTop: "65px" }}>
                <Col md="8" style={{ height: '85vh' }}>

                    <GoogleMap
                        id="map"
                        mapContainerStyle={mapContainerStyle}
                        zoom={15}
                        center={center}
                        options={options}
                        onLoad={onMapLoad}
                    >
                        <Marker
                            key='10001'
                            position={center}
                            icon={{
                            url: mapSelf,
                            origin: new window.google.maps.Point(0, 0),
                            anchor: new window.google.maps.Point(15, 15),
                            scaledSize: new window.google.maps.Size(30, 30),
                            }}
                        />

                        {mapData}

                        {selected ? (
                        <InfoWindow
                            position={{ lat: Number(selected.lat), lng: Number(selected.lng) }}
                            onCloseClick={() => {
                            setSelected(null);
                            }}
                        >
                            <div style={{maxWidth:'220px'}}>
                                <h5>{MaskCharacter(selected.fpo_name, '#', 2)}</h5>
                                <div className='mb-1'><b>Distance:</b> {Number(selected.distance).toFixed(2)} Km</div>
                                <div className='mb-1'><b>Moble No:</b> {MaskCharacter(selected.mobile_no, '*', 3)}</div>
                                <div className='mb-1'><b>Address:</b> {selected.address}</div>
                            </div>
                        </InfoWindow>
                        ) : null}
                        
                    </GoogleMap>
                </Col>
                <Col md="4" className="scrollNone" style={{ height: '85vh', overflow: 'auto' }}>
                    <Card color="light" className='mb-4'>
                        <CardBody>
                            <Row>
                                <Col xs="12" className='mb-1'>
                                    <Label for="education"> Select Product </Label>
                                </Col>

                                <Col xs="12" className='mb-2'>
                                    <Row>
                                        { product.length > 0 && 
                                            product.slice(0, 5).map((row,index) => {
                                                return(
                                                    <Col xs="6" md="4" key={index}>
                                                        <div className='d-flex justify-content-between align-items-center bg-white p-1 mb-2 rounded-3'>
                                                            <Input type="checkbox" checked={selProduct.includes(row.prod_id)} onChange={()=>select_product_handler(row.prod_id)} />
                                                            <div> <b>{row.prod_name}</b> </div>
                                                            <img className='img-fluid' width="40px" src={require('../../assets/img/prod_4.jpg')} alt="img" />
                                                        </div>
                                                    </Col>
                                                )
                                            })

                                        }
                                        <Col xs="6" md="4">
                                            <Button color='primary mt-2' size="sm" onClick={() => setProductModal(true)}> More Products </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="12" className='mb-2'>
                                    <Label for="quantity"> Quantity ( Min: <b>{quantity[0]}</b> to Max: <b>{quantity[1]}</b> ) </Label>
                                    <Slider
                                        getAriaLabel={() => 'Quantity range'}
                                        value={quantity}
                                        onChange={(event, newValue) => setQuantity(newValue)}
                                        valueLabelDisplay="auto"
                                        getAriaValueText={valuetext}
                                    />
                                </Col>

                                <Col xs="12" className='mb-2'>
                                    <Button color='primary' size="sm" block onClick={search_handler} disabled={loading}> <FaSearchLocation /> Search {loading && <Spinner size="sm"  > Loading... </Spinner>}</Button>
                                </Col>
                                {loading &&
                                    <Col xs="12" className='text-center'>
                                        <Spinner color="primary" size="" type="grow" > Loading... </Spinner>
                                    </Col>
                                }

                                {mapViewData}
                                
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            

            <Modal
                centered
                scrollable
                size="md"
                isOpen={productModal}
                toggle={() => setProductModal(!productModal)}
            >
                <ModalHeader toggle={() => setProductModal(!productModal)}>
                    Product List
                </ModalHeader>
                <ModalBody className='bg-light'>
                    <Row>
                        { product.length > 0 && 
                            product.map((row,index) => {
                                return(
                                    <Col md={6} key={index}>
                                        <div className='d-flex justify-content-between align-items-center bg-white p-1 mb-2 rounded-3'>
                                            <Input type="checkbox" checked={selProduct.includes(row.prod_id)} onChange={()=>select_product_handler(row.prod_id)} />
                                            <div> <b>{row.prod_name}</b> </div>
                                            <img className='img-fluid' width="40px" src={require('../../assets/img/prod_4.jpg')} alt="img" />
                                        </div>
                                    </Col>
                                )
                            })

                        }
                        
                    </Row>

                </ModalBody>
            </Modal>
        </div>
    )
}

export default ProductMap;