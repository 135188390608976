import React from 'react';

import {
    Row,
    Col,
} from 'reactstrap';

const FooterSmall = () => {
    return (
            <Row className='p-1'>
                <Col xs="12" className='text-center text-muted'>
                    <small>&copy; All Rights Reserved By I-Milk, 2022</small>
                </Col>
            </Row>
    )
}

export default FooterSmall;