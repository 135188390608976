import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderFarmer from '../../component/layout/HeaderFarmer';
import FooterSmall from '../../component/layout/FooterSmall';
import Page from '../../component/layout/Page';

import { notification } from '../../component/hocs/notification';

import axios from 'axios';
import {mainUrl} from '../../MainUrl';

import { Row, Col, Form, Label, Input, Button, Card, CardBody, Spinner, InputGroup, InputGroupText, FormGroup } from 'reactstrap';

const FarmerAdd = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState(1);

    const [name, setName] = useState('');
    const [farmerReg, setFarmerReg] = useState('');
    const [gender, setGender] = useState('');
    const [mobileNo, setMobileNo] = useState("");
    const [education, setEducation] = useState("");
    
    const [state, setState] = useState("");
    const [stateOpt, setStateOpt] = useState("");
    const [district, setDistrict] = useState("");
    const [districtOpt, setDistrictOpt] = useState("");
    const [village, setVillage] = useState("");
    const [address_1, setAddress_1] = useState("");
    const [address_2, setAddress_2] = useState("");
    const [pincode, setPincode] = useState("");
    const [milk, setMilk] = useState(0);
    const [animalCount, setAnimalCount] = useState(0);
    //const [villageOpt, setVillageOpt] = useState("");
    
    const [pdfFile, setPdfFile] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
        //get_state_list();
    }, [])

    /*const get_state_list = () => {
        axios({
            method: 'post',
            url: mainUrl + 'master/getStateList',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data.status === 1){
                    let list = response.data.data.map((row, index) => {
                        return <option value={row.state_id}> {row.state_name} </option>
                    });

                    setStateOpt(list);
                }

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const get_district_list = (e) => {
        setState(e.target.value);
        let postData = {"state_id": e.target.value}
        axios({
            method: 'post',
            url: mainUrl + 'master/getDistrictList',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data.status === 1){
                    let list = response.data.data.map((row, index) => {
                        return <option value={row.district_id}> {row.district_name} </option>
                    });

                    setDistrictOpt(list);
                }

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }
    
    const get_village_list = (e) => {
        setDistrict(e.target.value);
        let postData = {"district_id": e.target.value}
        axios({
            method: 'post',
            url: mainUrl + 'master/getVillageList',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data.status === 1){
                    let list = response.data.data.map((row, index) => {
                        return <option value={row.village_id}> {row.village_name} </option>
                    });

                    setVillageOpt(list);
                }

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }*/

    const farmer_add_handler = (e) => {
        e.preventDefault();

        let postData = {
            "farmer_reg": farmerReg,
            "fpo_code": sessionStorage.getItem("user_id"),
            "name": name,
            "gender": gender,
            "mobile_no": mobileNo,
            "photo": "farmer.png",
            "education": education,
            "state": state,
            "district": district,
            "village": 0,
            "address_1": address_1,
            "address_2": address_2,
            "pincode": pincode,
            "milk": milk,
            "animal_count": animalCount,
        }

        axios({
            method: 'post',
            url: mainUrl + 'user/farmerAdd',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                
                if(response.data.status === 1){
                    let notify = notification({ message: response.data.message, type: 'success' });
                    notify();
                    navigate('/farmer-list');
                }else{
                    let notify = notification({ message: response.data.message, type: 'error' });
                    notify();
                    setLoading(false);
                }

            }).catch(error => {
                console.log(error)
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const upload_excel = (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append("pic", pdfFile[0]);
        formData.append("fpo_code", sessionStorage.getItem('user_id'));

        axios.post(mainUrl + 'user/farmerBulkUpload', formData)
            .then(function (response) {
                if(response.data.status === 1){
                   
                    let notify = notification({ message: response.data.message, type: 'success' });
                    notify();
                    setPdfFile('')
                    setTimeout(() => {
                        navigate('/farmer-list');
                    }, 2000);
                    
                }else{
                    let notify = notification({ message: response.data.message, type: 'error' });
                    notify();
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });

    }

    return (
        <div>
            <HeaderFarmer />
            <Page>
                <Row className='p-3'>
                    <Col md={{
                        offset: 2,
                        order: 2,
                        size: 8
                    }}>
                        
                        <Row>
                            <Col xs={12} className='mb-3'>
                                <div className='profileName'>
                                    Add New Farmer
                                </div>
                            </Col>
                            <Col xs={12} className='d-flex justify-content-center'>
                                <Button color={mode === 1 ?'primary' : 'light'} size="md" onClick={()=>setMode(1)} > Add single Farmer </Button>
                            
                                <Button color={mode === 2 ?'primary' : 'light'} size="md" onClick={()=>setMode(2)} > Add Multiple Farmer </Button>
                            </Col>
                        </Row>
                        {mode === 1 && 
                        <Card>
                            <CardBody>
                                <Form onSubmit={farmer_add_handler}>

                                    <Row>
                                        <Col md="6" className='mb-2'>
                                            <Label for="fpoName">Name</Label>
                                            <Input id="fpoName" bsSize="sm" type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Farmer Name" required/>
                                        </Col>
                                        <Col md="6" className='mb-2'>
                                            <Label for="farmerReg">Farmer Registration No</Label>
                                            <Input id="farmerReg" bsSize="sm" type="text" value={farmerReg} onChange={(e) => setFarmerReg(e.target.value)} placeholder="Farmer Registration No." required/>
                                        </Col>
                                        <Col md="6" className='mb-2'>
                                            <Label for="gender">Gender</Label>
                                            <Input id="gender" bsSize="sm" type="select" value={gender} onChange={(e) => setGender(e.target.value)} required>
                                                <option value="0"> --Select Gender--</option>
                                                <option value="1"> Male </option>
                                                <option value="2"> Female </option>
                                            </Input>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <Label for="mobileNo"> Mobile Number</Label>
                                            <InputGroup size="sm">
                                                <InputGroupText> +91 </InputGroupText>
                                                <Input id="mobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} placeholder="Your Mobile Number" required/>
                                            </InputGroup>

                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Label for="education"> Chief Wage Earner : Education </Label>
                                            <Input id="education" bsSize="sm" type="select" value={education} onChange={(e) => setEducation(e.target.value)} required>
                                                <option value=""> --Select Education--</option>
                                                <option value="101"> Illiterate </option>
                                                <option value="102"> School-Upto 4 year </option>
                                                <option value="103"> School-5 to 9 year </option>
                                                <option value="104"> SSC / HS </option>
                                                <option value="105"> Graduate / Post Graduate: General </option>
                                                <option value="106"> Graduate / Post Graduate: Professional </option>
                                            </Input>
                                        </Col>
                                        {/*
                                        <Col md="6" className='mb-2'>
                                            <Label for="State">State</Label>
                                            <Input id="State" bsSize="sm" type="select" value={state} onChange={get_district_list} required>
                                                <option value="0"> --Select State--</option>
                                                {stateOpt}
                                            </Input>
                                        </Col>
                                        <Col md="6" className='mb-2'>
                                            <Label for="district">District</Label>
                                            <Input id="district" bsSize="sm" type="select" value={district} onChange={(e)=> setDistrict(e.target.value)} required>
                                                <option value="0"> --Select District--</option>
                                                {districtOpt}
                                            </Input>
                                        </Col>
                                         */}
                                         <Col md="6" className='mb-2'>
                                            <Label for="State">State</Label>
                                            <Input id="State" bsSize="sm" type="text" value={state} onChange={(e)=>setState(e.target.value)} placeholder='State' required />
                                        </Col>
                                        <Col md="6" className='mb-2'>
                                            <Label for="district">District</Label>
                                            <Input id="district" bsSize="sm" type="text" value={district} onChange={(e)=>setDistrict(e.target.value)} placeholder='District' required />
                                        </Col>
                                        <Col md="6" className='mb-2'>
                                            <Label for="village">Address Line - 1</Label>
                                            <Input id="village" bsSize="sm" type="textarea" value={address_1} onChange={(e)=> setAddress_1(e.target.value)} placeholder='Add Address Line - 1' required />
                                        </Col>
                                        <Col md="6" className='mb-2'>
                                            <Label for="address">Address Line - 2</Label>
                                            <Input id="address" bsSize="sm" type="textarea" value={address_2} onChange={(e)=> setAddress_2(e.target.value)}  placeholder='Add Address Line - 2' />
                                        </Col>
                                        <Col md="6" className='mb-2'>
                                            <Label for="pincode">Pincode</Label>
                                            <Input id="pincode" bsSize="sm" type="number" value={pincode} onChange={(e)=> setPincode(e.target.value)} placeholder='Enter six digits Pincode' required />
                                        </Col>
                                        <Col md="6" className='mb-2'>
                                            <Label for="animalCount">Animal Count</Label>
                                            <Input id="animalCount" bsSize="sm" type="number" value={animalCount} onChange={(e)=> setAnimalCount(e.target.value)} placeholder='Enter animal count' required />
                                        </Col>
                                        <Col md="6" className='mb-2'>
                                            <Label for="milk">Daily Milk Production (In Liters)</Label>
                                            <Input id="milk" bsSize="sm" type="number" value={milk} onChange={(e)=> setMilk(e.target.value)} placeholder='Enter daily milk production (In Liters)' required />
                                        </Col>
                                        <Col md="12" className='mb-2 text-end'>
                                            <Button color='primary' size="sm" disabled={loading}> Submit {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                        }
                        {mode === 2 && 
                            <Card>
                                <CardBody>
                                    <FormGroup row>
                                        <Col sm={6}>
                                            <Input bsSize="sm" type="file" onChange={(e)=> setPdfFile(e.target.files)} />
                                            <small className='text-muted fs-10'><i>*Select Excel file only. Download sample <a href={require('../../assets/farmerUploadExcel.xlsx')} target="_blank"> Excel file</a>.</i></small>
                                        </Col>
                                        <Col sm={4}>
                                            <Button color='primary' size="sm" disabled={loading} onClick={upload_excel}
                                            > Upload {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
                                        </Col>
                                    </FormGroup>
                                </CardBody>
                            </Card>    
                        }
                        

                    </Col>
                </Row>

            </Page>
            <FooterSmall />
        </div>


    )
}

export default FarmerAdd;