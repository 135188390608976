import React, {useState, useEffect} from 'react';
import Header from '../component/layout/Header';
import Footer from '../component/layout/Footer';

import { Row, Col, Form, Input, Label, Button } from 'reactstrap';
import Banner from '../component/utility/Banner';

import {MdLocationPin, MdPhoneInTalk, MdEmail} from 'react-icons/md';

const Contact = () => {
    const [fName, setFName] = useState('');
    const [designation, setDesignation] = useState('');
    const [email, setEmail] = useState('');
    const [business, setBusiness] = useState('');
    const [city, setCity] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const send_handler = (e) => {
        e.preventDefault();
    }

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])
    
    return (
        <div> 
            <Header />
            <Banner title="CONTACT US" page="3" />
            <Row className="p-5">
                <Col xs="12" className='mb-4'>
                    <h3>Enquire Now</h3>
                    <h6></h6>
                </Col>
                <Col md="7">
                    <Form onSubmit={send_handler}>
                        <Row>
                            <Col md="6" className='mb-2'>
                                <Label>Name</Label>
                                <Input type="text" value={fName} onChange={(e)=>setFName(e.target.value)} placeholder="Name" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <Label>Designation </Label>
                                <Input type="text" value={designation} onChange={(e)=>setDesignation(e.target.value)} placeholder="Designation " />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <Label>Hotel / Business </Label>
                                <Input type="text" value={business} onChange={(e)=>setBusiness(e.target.value)} placeholder="Hotel / Business " />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <Label>City</Label>
                                <Input type="text" value={city} onChange={(e)=>setCity(e.target.value)} placeholder="City" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <Label>Email Address</Label>
                                <Input type="email" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="example@gmail.com" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <Label>Phone</Label>
                                <Input type="text" value={phone} onChange={(e)=>setPhone(e.target.value)} placeholder="90000000000" />
                            </Col>
                            <Col xs="12" className='mb-3'>
                                <Label>Message</Label>
                                <Input type="textarea" row="5" value={message} onChange={(e)=>setMessage(e.target.value)} placeholder="Message goes here..." />
                            </Col>
                            <Col md="2" className='mb-2'>
                                <Button block size="lg" > Send </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col md="1"></Col>
                <Col md="4" className='d-none d-md-block'>
                    <Row className='mb-3'>
                        <Col xs="3" className='secondaryBg text-center p-2'>
                            <MdPhoneInTalk color="#fff" size={45} />
                        </Col>
                        <Col xs="9" className='lightBg p-1 ps-2'>
                            <div><b>Phone</b></div>
                            <div>+91 9810334380, +91 9811818938</div>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col xs="3" className='secondaryBg text-center p-2'>
                            <MdEmail color="#fff" size={45} />
                        </Col>
                        <Col xs="9" className='lightBg p-1 ps-2'>
                            <div><b>Email</b></div>
                            <div>rahul@imilk.in</div>
                            <div>ranjesh@imilk.in</div>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col xs="3" className='secondaryBg text-center p-2'>
                            <MdLocationPin color="#fff" size={45} />
                        </Col>
                        <Col xs="9" className='lightBg p-1 ps-2'>
                            <div><b>Address</b></div>
                            <div>B1/H3, Mohan Co-operative, Mathura Rd, Industrial Area, Block B, New Delhi, Delhi-110044</div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Footer />
        </div>
    )
}

export default Contact;