import React, {useState} from 'react';

import { FaFacebookSquare, FaTwitterSquare, FaLinkedin, FaInstagramSquare} from 'react-icons/fa';
import {MdEmail} from 'react-icons/md';
import logo_blue from '../../assets/img/logo_blue.png';
import contact from '../../assets/img/contact.png';

import {
    Row,
    Col,
    Input,
    InputGroup,
    InputGroupText

} from 'reactstrap';

function Footer() {
    const [email, setEmail] = useState('');

    const send_email_handler = () => {

    }

    return (
        <div className='footerPage'>
            <div className='borderBottom-5'></div>
            <Row className='p-5 primaryBg' style={{color:"#fff"}}>
                <Col md="3" className='text-center'>
                    <img className='img-fluid' src={logo_blue} alt="img" />
                </Col>
                <Col md="5">
                    <Row className=''> 
                        <Col xs="3" className="p-0">
                            <img className='img-fluid' src={contact} alt="img" />
                        </Col>
                        <Col xs="9" className='d-flex align-items-center'>
                            <div>
                                <h3>May I Help You?</h3>
                                <small><b><i>Connect with us - join the growing community of I-Milkers!</i></b></small>
                            </div>
                        </Col>
                        <Col xs="12" className='mt-5'>
                            <Row className=''>
                                
                                <Col md="4" className='mb-3'>
                                    <h6>Message us on WhatsApp or Give us a call</h6>
                                    <div> 
                                        <a style={{color:"#fff"}} href="https://api.whatsapp.com/send?phone=9810334380" target="_blank">+91 9810334380</a><br/>
                                        <a style={{color:"#fff"}} href="https://api.whatsapp.com/send?phone=9811818938" target="_blank">+91 9811818938</a>
                                    </div>
                                </Col>
                                <Col md="4" className='mb-3'>
                                    <h6>Send us an email</h6>
                                    <div>
                                        <a style={{color:"#fff"}} href = "mailto: rahul@imilk.in">rahul@imilk.in</a><br/>
                                        <a style={{color:"#fff"}} href = "mailto: ranjesh@imilk.in">ranjesh@imilk.in</a>
                                    </div>
                                </Col>
                                <Col md="4" className='mb-3'>
                                    <h6>You can Reach us</h6>
                                    <div>B1/H3, Mohan Co-operative, Mathura Rd, 
                                        Industrial Area, Block B, 
                                        New Delhi,<br/> 
                                        Delhi-110044
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    
                </Col>
                <Col xs="12" md="4" className='text-center'>
                    <h5 className='mt-3'>Our Social Media Platform</h5>
                    <div className='socialIcon mb-5'>
                        <a href="#" target="_blank"><FaFacebookSquare className='me-3'/></a> 
                        <a href="#" target="_blank"><FaTwitterSquare className='me-3'/></a>
                        <a href="#" target="_blank"><FaInstagramSquare className='me-3'/></a>
                        <a href="#" target="_blank"><FaLinkedin className='me-3'/></a>
                    </div>
                    <div className='pt-2'>
                        
                        <InputGroup className='mt-3' size="lg">
                            <Input type="email" value={email} placeholder='Enter your email address' onChange={(e) => setEmail(e.target.value)}/>
                            <InputGroupText className='secondaryBg cursor' onClick={send_email_handler}> Send </InputGroupText>
                        </InputGroup>
                    </div>
                </Col>
            </Row>
            
            
        </div>
    )
}

export default Footer;