import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Col, } from 'reactstrap';

import slide from '../assets/img/testimonial.png';

const testimon_1 = <div>
    <div>Our guests are those who love nature, we serve them all authentic things including vegetables grown on our farm We will love to source fresh milk directly from farmers on I MILK, delivered through a sustainable cold supply chain It fits well into our resort 's DNA.</div>
    <div>- A Resort Manager at Manesar -</div>
</div>
const testimon_2 = <div>
    <div>The hassle of daily sourcing from unorganized sector or buying milk in pouches from shops is taking too much of our time and resource. We support I MILK initiative which enables fresh milk sourcing directly from nearby farmers and delivered to our doorstep, all at our fingertip through a friendly app.</div>
    <div>- A hotel owner at Jodhpur -</div>
</div>
const testimon_3 = <div>
    <div>In the food and beverages segment, hotels have increased locally-sourced food ingredients such as condiments, vegetables, raw meat, seafood, exotic fruits, dairy and processed foods. In 2000, around 85% ingredients were locally-sourced, which has increased to 88% in 2020.</div>
    <div>- Hotel Association of India in Mint -</div>
</div>


const Testimonial = () => {
    return (

        
            <Col xs="12">
                <Carousel 
                    showStatus={false}
                    showThumbs={false}
                >
                    <div>
                        <img src={slide} alt="bg" />
                        <p className="legendText">{testimon_1}</p>
                    </div>
                    <div>
                        <img src={slide} alt="bg" />
                        <p className="legendText">{testimon_2}</p>
                    </div>
                    <div>
                        <img src={slide} alt="bg" />
                        <p className="legendText">{testimon_3}</p>
                    </div>
                    
                </Carousel>
            </Col>

    )
}

export default Testimonial;