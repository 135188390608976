import React from 'react';
import slide_1 from './../../assets/img/slide_1.png';
import slide_2 from './../../assets/img/slide_2.png';
import slide_3 from './../../assets/img/slide_3.png';

import slide_m_1 from './../../assets/img/slide_m_1.png';
import slide_m_2 from './../../assets/img/slide_m_2.png';
import slide_m_3 from './../../assets/img/slide_m_3.png';

import { UncontrolledCarousel, } from 'reactstrap';

function Slider() {
    return (
        <>
            <div className='d-none d-md-flex'>
                <UncontrolledCarousel
                    items={[
                        { altText: 'Slide 1', key: 1, src: slide_1 },
                        { altText: 'Slide 2', key: 2, src: slide_2 },
                        { altText: 'Slide 3', key: 3, src: slide_3 },
                    ]}
                />
            </div>
            <div className='d-flex d-md-none'>
                <UncontrolledCarousel
                    items={[
                        { altText: 'Slide 1', key: 1, src: slide_m_1 },
                        { altText: 'Slide 2', key: 2, src: slide_m_2 },
                        { altText: 'Slide 3', key: 3, src: slide_m_3 },
                    ]}
                />
            </div>
        </>
        
    )

}
export default Slider;