import React from 'react';

const Banner = ({title, page, ...props}) => {
    return (
        <div className={'banner '+ ('pic_'+page+'') }>
            <h1 className='text-primary' style={{fontSize: '3.2em', paddingTop: '150px', paddingLeft: '60px'}}>{title}</h1>
        </div>
    )
}

export default Banner;