import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderFarmer from '../../component/layout/HeaderFarmer';
import FooterSmall from '../../component/layout/FooterSmall';
import Page from '../../component/layout/Page';
import FarmerCard from '../../component/utility/farmer/FarmerCard';

import { notification } from '../../component/hocs/notification';

import axios from 'axios';
import {mainUrl} from '../../MainUrl';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import {FaPlusCircle } from 'react-icons/fa';
import ExcelExport from '../../component/utility/ExcelExport';

const FarmerList = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [farmers, setFarmers] = useState('');
    const [excelData, setExcelData] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        getfarmerlist();
    }, []);

    const goto_farmer_add = () => {
        navigate('/add-farmer');
    }

    const getfarmerlist = () => {
        let postData = {"fpo_code": sessionStorage.getItem('user_id')}
        axios({
            method: 'post',
            url: mainUrl + 'user/getFarmerList',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data.status === 1){
                   let list = response.data.data.map((row, index) => {
                        return(<FarmerCard data={row} key={index} />)
                   });
                   setFarmers(list);
                   // excel download data
                   let e_list = response.data.data.map((row, index) => {
                        return{
                            "Sl. No.": index + 1,
                            "Farmer Name": row.name,
                            "Farmer Registration No": row.farmer_reg,
                            "Gender": row.gender_details,
                            "Education": row.education_detail,
                            "Animal Count": row.animal_count,
                            "Daily Milk Production (In Liters)": row.productList[0]? row.productList[0].max_qty : 0,
                            "State": row.address_detail.state,
                            "District": row.address_detail.district,
                            "Village": row.address_detail.village,
                            "Address Line 1": (row.address_detail.address_1 === null || row.address_detail.address_1 === "0") ? ' ' : row.address_detail.address_1,
                            "Address Line 2": (row.address_detail.address_2 === null || row.address_detail.address_2 === "0") ? ' ' : row.address_detail.address_2,
                        }
                    });
                    setExcelData(e_list);
                }else{
                    
                    let notify = notification({ message: response.data.message, type: 'error' });
                    notify();
                    setLoading(false);
                }

            }).catch(error => {
                console.log(error)
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    return (
        <div>
            <HeaderFarmer />
            <Page>
                <Row className='p-3'>
                    <Col xs="12">
                        <Card className='mb-2'>
                            <CardBody className='d-flex justify-content-between'>
                                <h5>Farmer List</h5>
                                <div>
                                    { (excelData && excelData.length > 0) &&
                                        <ExcelExport data={excelData} name="farmer_list" />
                                    }

                                    <Button className='ms-3' color='primary' size="sm" onClick={goto_farmer_add} ><FaPlusCircle size={20} /> Add New Farmer </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    {farmers}
                </Row>
                
            </Page>
            <FooterSmall />
        </div>


    )
}

export default FarmerList;