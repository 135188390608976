import React, { useEffect, useState } from 'react';
import HeaderBuyer from '../../component/layout/HeaderBuyer';
import FooterSmall from '../../component/layout/FooterSmall';
import Page from '../../component/layout/Page';

import { Row, Col, Card, CardBody, CardTitle, Label, Input, Button, Modal, ModalBody, ModalHeader,} from 'reactstrap';

import axios from 'axios';
import { mainUrl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';

const FarmerCard = ({ data }) => {
    const [profileModal, setProfileModal] = useState(false);
    return (
        <>
            <Col md={3}>
                <Card className='cursor mb-2' onClick={() => setProfileModal(true)}>
                    <CardBody>
                        <CardTitle tag="h5">{data.f_name}</CardTitle>
                        <div className="d-flex justify-content-between">
                            <div><b>Registration No.:</b></div>
                            <div>{data.farmer_code}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div><b>Milk Qty:</b></div>
                            <div>{data.qty} Ltrs</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div><b>Date:</b></div>
                            <div>{data.trans_date}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div><b>Rate / Unit:</b></div>
                            <div> &#8377; {Number(data.rate).toFixed(2)}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div><b>Total:</b></div>
                            <div> &#8377; {Number(data.total).toFixed(2)}</div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Modal
                centered
                scrollable
                size="md"
                isOpen={profileModal}
                toggle={() => setProfileModal(!profileModal)}
            >
                <ModalHeader toggle={() => setProfileModal(!profileModal)}>
                    Quality Parameters
                </ModalHeader>
                <ModalBody className='bg-light'>
                    <Row>
                        <Col xs={12}>

                            <div className="d-flex justify-content-between">
                                <div><b>Qty: </b></div>
                                <div>{data.transData.qty} Ltrs</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Fat: </b></div>
                                <div>{data.transData.fat}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>SNF: </b></div>
                                <div>{data.transData.snf}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>CLR: </b></div>
                                <div>{data.transData.clr}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Density: </b></div>
                                <div>{data.transData.density}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Lactose: </b></div>
                                <div>{data.transData.lactose}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Salts: </b></div>
                                <div>{data.transData.salts}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Protein: </b></div>
                                <div>{data.transData.protein}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Temp: </b></div>
                                <div>{data.transData.temp}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Added Water: </b></div>
                                <div>{data.transData.added_water}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Freezing Point: </b></div>
                                <div>{data.transData.freezing_point}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Milk Type: </b></div>
                                <div>{data.transData.milk_type}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Rate: </b></div>
                                <div>&#8377; {Number(data.transData.rate).toFixed(2)}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Total: </b></div>
                                <div>&#8377; {Number(data.transData.total).toFixed(2)}</div>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}
const Transaction = () => {
    const [userDetail, setUserDetail] = useState(() => JSON.parse(sessionStorage.getItem('user_detail')));
    const [loading, setLoading] = useState(false);
    const [toDate, setToDate] = useState("");
    const [farmerList, setFarmerList] = useState("");
    const [totalQty, setTotalQty] = useState(0);
    


    const submit_handler = (e) => {
        e.preventDefault();
        //getTransactionDtl

        const postData = { "to_date": toDate, "user_id": sessionStorage.getItem('user_id') };
        axios.post(mainUrl + 'activity/getTransactionDtl', postData)
            .then(function (response) {
                if (response.data.status === 1) {
                    let result = response.data.data.allData;
                    let total = 0;
                    let farmer_list = result.map((row, index) => {
                        total = total + Number(row.qty);
                        return (
                            <FarmerCard data={row} />
                        )
                    })
                    setFarmerList(farmer_list);
                    setTotalQty(total);
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }
    return (
        <>
            <HeaderBuyer />
            <Page>
                <Row className='p-5'>
                    <div className='profileName mb-3'>
                        Transaction Data
                    </div>
                    <Col md="12" className='mb-3'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={6}>
                                        <Row>
                                            <Col md={4}>
                                                <Label for="mobileNo"> Transaction Date </Label>
                                                <Input className='mb-2' bsSize="sm" type="date" onChange={(e) => setToDate(e.target.value)} />
                                            </Col>
                                            <Col md={4}>
                                                <Button className='mb-2 mt-xs-0 mt-md-4 ' color='primary' size="sm" onClick={submit_handler} > Search </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    {farmerList}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Page>
            <FooterSmall />
        </>
    )
}
export default Transaction;