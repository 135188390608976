import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import HeaderFarmer from '../../component/layout/HeaderFarmer';
import FooterSmall from '../../component/layout/FooterSmall';
import Page from '../../component/layout/Page';
import FarmerList from '../../component/utility/farmer/FarmerCard';


import axios from 'axios';
import {mainUrl} from '../../MainUrl';

import {
    Row,
    Col,
    Card,
    CardBody,

} from 'reactstrap';

const FpoDashboard = () => {

    const navigate = useNavigate();
    
    const [products, setProducts] = useState('');
    const [farmers, setFarmers] = useState('0');
    const [production, setProduction] = useState('0');

    useEffect(() => {
        window.scrollTo(0, 0);
        get_all_product();
        get_farmer_count();
        get_production_data();
    }, [])

    const goto_farmer_page = () => {
        navigate('/farmer-list');
    }
    const goto_production_page = () => {
        navigate('/add-daily-production');
    }
    const goto_product_farmer_list = (id) => {
        navigate('/product-farmer-list', {state:{prod_id: id}});
    }

    const get_farmer_count = () => {
        let postData = {"fpo_code": sessionStorage.getItem('user_id')}
        axios({
            method: 'post',
            url: mainUrl + 'user/getFarmerList',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data.status === 1){
                   setFarmers(response.data.data.length);
                }

            }).catch(error => {
                //let notify = notification({ message: "Failed! Try Again", type: 'error' });
                //notify();
            })
    }

    const get_production_data = () => {
        let postData = {"user_id": sessionStorage.getItem('user_id')}
        axios({
            method: 'post',
            url: mainUrl + 'activity/getdbProduction',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data.status === 1){
                    setProduction(response.data.data.allData);
                }

            }).catch(error => {
                //let notify = notification({ message: "Failed! Try Again", type: 'error' });
                //notify();
            })
    }

    const get_all_product = () => {
        let postData = {"fpo_code": sessionStorage.getItem("user_id")};

        axios.post(mainUrl + 'activity/fpoProductView', postData)
            .then(function (response) {
                if(response.data.status === 1){
                    let ProductList = response.data.data;
                    let product_list = [];
                    let product = {
                        "prod_id": 0,
                        "product_name":'',
                        "min_qty": 0,
                        "uom": 0,
                    };
                    let prod_id = ProductList[0].prod_id;
                    let total = 0;

                    for(let i=0; i < ProductList.length; i++ ){
                        if(prod_id == ProductList[i].prod_id){
                            total = total + Number(ProductList[i].min_qty);
                            product = {
                                "prod_id": ProductList[i].prod_id,
                                "product_name": ProductList[i].product_name,
                                "min_qty": total,
                                "uom": ProductList[i].uom,
                            }
                        }else{
                            prod_id = ProductList[i].prod_id;
                            total = 0;
                            product_list.push(product);

                            total = total + Number(ProductList[i].min_qty);
                            product = {
                                "prod_id": ProductList[i].prod_id,
                                "product_name": ProductList[i].product_name,
                                "min_qty": total,
                                "uom": ProductList[i].uom,
                            }
                        }
                    }
                    product_list.push(product);
                   
                    let list = product_list.map((row, index) => {
                        return(
                            <Col md={4} className="mb-3">
                                <div className='iconWidget' onClick={() => goto_product_farmer_list(row.prod_id)}>
                                    <Row>
                                        <Col xs={7} className="text-center mt-3">
                                            <h5 className='text-muted'>{row.product_name}</h5>
                                            <h4 className='text-secondary'>{row.min_qty} {row.uom === 1 ? "kg / day" : "Ltrs / day"} </h4>
                                        </Col>
                                        <Col xs={5} className="text-center mt-1">
                                            <img className='img-fluid' src={require('../../assets/img/prod_5.jpg')} alt="img" style={{maxHeight: '100px'}} />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        )
                    });

                    setProducts(list);
                    
                }
            })
            .catch(error => {
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });

    }

    return (
        <div>
            <HeaderFarmer />
            <Page>
                <Row className='p-3'>
                    <Col md={4} className="mb-3">
                        <div className='iconWidget cursor' onClick={goto_farmer_page}>
                            <Row>
                                <Col xs={7} className="text-center mt-3">
                                    <h5 className='text-muted'>Total Farmer</h5>
                                    <h4 className='text-secondary'>{farmers}</h4>
                                </Col>
                                <Col xs={5} className="text-center mt-1">
                                    <img className='img-fluid' src={require('../../assets/img/farmer.png')} alt="img" style={{maxHeight: '100px'}} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={4} className="mb-3">
                        <div className='iconWidget cursor' onClick={goto_production_page}>
                            <Row>
                                <Col xs={7} className="text-center mt-3">
                                    <h5 className='text-muted'>Milk Production</h5>
                                    <h6 className='text-secondary'>Last Month: {production.lastMonthQty} Ltrs</h6>
                                    <h6 className='text-secondary'>This Month: {production.thisMonthQty} Ltrs</h6>
                                </Col>
                                <Col xs={5} className="text-center mt-1">
                                    <img className='img-fluid' src={require('../../assets/img/prod_4.jpg')} alt="img" style={{maxHeight: '100px'}} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    {products}
                </Row>
                
            </Page>
            <FooterSmall />
        </div>


    )
}

export default FpoDashboard;