import React, { useState, useEffect } from 'react';

import axios from 'axios';
import {mainUrl} from '../../../MainUrl';
import { notification } from '../../hocs/notification';

import { Row, Col, Card, CardBody, Button, Input, Form, Label, Spinner, Modal, ModalHeader, ModalBody } from 'reactstrap';

import {FaPlusCircle} from 'react-icons/fa';
import Select from 'react-select';

const ProductDetails = () => {

    const [loading, setLoading] = useState(false);
    const [userDetail, setUserDetail] = useState( () => JSON.parse(sessionStorage.getItem('user_detail')) );

    const [userProduct, setUserProduct] = useState('');
    const [productList, setProductList] = useState('');
    const [selectedProd, setSelectedProd] = useState('');
    const [productOpt, setProductOpt] = useState('');
    const [uomOpt, setUomOpt] = useState('');
    const [uomList, setUomList] = useState('');
    const [modalProd, setModalProd] = useState(false);
    const [newProd, setNewProd] = useState('');
    const [qty, setQty] = useState('');
    const [minQty, setMinQty] = useState('0');
    const [maxQty, setMaxQty] = useState('0');

    useEffect(() => {
        get_product_list();
        get_uom_list();
        get_user_product_list();
    }, [])

    const get_user_product_list = () => {
        let postData = { "user_id": JSON.parse(sessionStorage.getItem('user_detail')).farmer_code };
        axios({
            method: 'post',
            url: mainUrl + 'master/getUserProduct',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.data;
                setUserProduct(list);
            })
            .catch(error => {
                let notify = notification({ message: "Product list not found", type: 'error' });
                notify();
            })
    }
    const get_product_list = () => {
        axios({
            method: 'post',
            url: mainUrl + 'master/getProduct',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let prodlist = response.data.data;
                setProductList(prodlist);

                let list = response.data.data.map((row) => {
                    return { value: row.prod_id, label: row.prod_name, uom:row.uom_type }
                });

                setProductOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "Product list not found", type: 'error' });
                notify();
            })
    }
    const get_uom_list = () => {
        axios({
            method: 'post',
            url: mainUrl + 'master/getUom',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                
                let list = response.data.data;

                setUomList(list);

            })
            .catch(error => {
                let notify = notification({ message: "Product list not found", type: 'error' });
                notify();
            })
    }

    const add_product_handler = (e) => {
        e.preventDefault();
        let postData = {
            "user_id": userDetail.farmer_code,
            "user_type": 1,
            "prod_id": newProd,
            "uom": qty,
            "min_qty": minQty,
            "max_qty": maxQty
        };

        axios({
            method: 'post',
            url: mainUrl + 'activity/addNewUserProduct',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if (response.data.status === 1) {
                    get_user_product_list();
                    setNewProd("");
                    setMinQty("");
                    setMaxQty("");
                    setModalProd(false);
                    let notify = notification({ message: response.data.message, type: 'success' });
                    notify();
                } else {
                    let notify = notification({ message: response.data.message, type: 'error' });
                    notify();
                }
            })
            .catch(error => {
                let notify = notification({ message: "Product list not found", type: 'error' });
                notify();
            })
    }

    const get_qty_handler = (opt) => {
        console.log(opt);
        setSelectedProd(opt);
        setNewProd(opt.value);
        let list = uomList.filter(r => r.type === opt.uom ).map((row, index) => {
            return(
                <option key={index} value={row.id}> {row.uom} </option>
            )
        });
        setUomOpt(list);
    }

    return (
        <>
        
            <Col md="12">
                <Card>
                    <CardBody>
                        <h5>My Products</h5>
                        <Row>
                            {userProduct.length > 0 &&
                                userProduct.slice(0, 5).map((row, index) => {
                                    return (
                                        <Col className='mb-2' xs={6} md={2} key={index}>
                                            <div className='mb-1 product'>
                                                <div className='text-center'>
                                                    <img className='img-fluid' width="40px" src={require('../../../assets/img/prod_5.jpg')} alt="img" />
                                                    <div> <b>{row.prod_name}</b> </div>
                                                </div>
                                                <div className='d-flex justify-content-between fs-12 text-muted'>
                                                    <div><b>Qty:</b></div>
                                                    <div>{row.uom.uom}</div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                            <Col xs={6} md={2}>
                                <div className="product h-100 w-100 d-flex justify-content-center align-items-center cursor" onClick={() => setModalProd(true)}>
                                    <div className='text-center'>
                                        <FaPlusCircle size={40} color="#2473cb" />
                                        <div>Add New Product</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>

            <Modal
                centered
                scrollable
                size="md"
                isOpen={modalProd}
                toggle={() => setModalProd(!modalProd)}
            >
                <ModalHeader toggle={() => setModalProd(!modalProd)}>
                    Add New Product
                </ModalHeader>
                <ModalBody className='bg-light'>
                    <Form onSubmit={add_product_handler}>
                        <Row>
                            <Col xs={12} className="mb-2">
                                <Label for="product"> Product </Label>
                                
                                <Select
                                    styles={{ option: (base) => ({ ...base, fontSize: '0.8em', lineHeight: '1' }) }}
                                    value={selectedProd}
                                    onChange={get_qty_handler}
                                    options={productOpt}
                                    maxMenuHeight={140}
                                />
                            </Col>
                            <Col xs={12} className="mb-2">
                                <Label for="qty"> Quantity </Label>
                                <Input id="qty" bsSize="sm" type="select" value={qty} onChange={(e)=> setQty(e.target.value)} required>
                                    <option value=""> --Select Qty/Day-- </option>
                                    {uomOpt} 
                                </Input>
                            </Col>
                            <hr />
                            <Col xs={12} className="mb-2">
                                <Button color='primary' size="sm" block disabled= {loading}> Submit {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>} </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>            
        </>
    )
}

export default ProductDetails;