import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import Header from '../../component/layout/Header';
import FooterSmall from '../../component/layout/FooterSmall';
import Page from '../../component/layout/Page';

import BuyerRegistration from '../../component/utility/buyer/BuyerRegistration';
import BuyerLogin from '../../component/utility/buyer/BuyerLogin';

const Buyer = () => {
    const [mode, setMode] = useState(1);
    const [mb_no, setMb_no] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <Header />
            <Page>
                <div className='d-flex align-items-center justify-content-center' style={{height: '84vh'}}>
                    { mode === 1 &&
                        <Row className='p-2 w-100'>
                            <Col md={{ offset: 4, order: 2, size: 4 }}>                    
                                <BuyerLogin signup={(mb)=>setMode(2)} />
                            </Col>
                        </Row>
                    }
                    { mode === 2 &&
                        <Row className='p-2 w-100'>
                            <Col md={{ offset: 3, order: 2, size: 6 }}>                    
                                <BuyerRegistration back={()=>setMode(1)} />
                            </Col>
                        </Row>
                    }
                </div>
            </Page>
            <FooterSmall />
        </div>


    )
}

export default Buyer;