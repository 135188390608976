import React, { useState, useEffect } from 'react';

import axios from 'axios';
import {mainUrl} from '../../../MainUrl';
import { notification } from '../../hocs/notification';

import { Row, Col, Card, CardBody, Button, Input, Label, Form, Spinner, Modal, ModalBody, ModalHeader, FormGroup, InputGroup, InputGroupText } from 'reactstrap';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { deepOrange, deepPurple } from '@mui/material/colors';

import {FaPlusCircle} from 'react-icons/fa';

const AnimalDetails = () => {

    const [loading, setLoading] = useState(false);
    const [modalAni, setModalAni] = useState(false);

    const [animalList, setAnimalList] = useState('');
    const [totalAnimal, setTotalAnimal] = useState('0');
    const [animalTypeOpt, setAnimalTypeOpt] = useState('');
    const [animalAgeOpt, setAnimalAgeOpt] = useState('');
    const [breedOpt, setBreedOpt] = useState('');
    const [breedTypeOpt, setBreedTypeOpt] = useState('');
    const [milkingTimeOpt, setMilkingTimeOpt] = useState('');
    const [milkingMethodOpt, setMilkingMethodOpt] = useState('');
    const [feedingPracticesOpt, setFeedingPracticesOpt] = useState('');

    const [animalType, setAnimalType] = useState('');
    const [animalAge, setAnimalAge] = useState('');
    const [breedType, setBreedType] = useState('');
    const [breed, setBreed] = useState('');
    const [milking, setMilking] = useState('');
    const [milkingTime, setMilkingTime] = useState('');
    const [milkingMethod, setMilkingMethod] = useState('');
    const [feedingPractices, setFeedingPractices] = useState('');
    const [concentrateFed, setConcentrateFed] = useState(0);
    const [ai, setAi] = useState(0);
    const [dung, setDung] = useState(0);
    const [methane, setMethane] = useState('');
    const [vaccination, setVaccination] = useState(0);

    
    useEffect(()=>{
        get_animal_details();
        get_all_options();
    },[])

    const get_all_options = () => {
        axios({
            method: 'post',
            url: mainUrl + 'master/getAddNewAnimalOption',
            headers: { 'Content-Type': 'application/JSON', }
        }) 
        .then(function (response) {
            if (response.data.status === 1) {
                let allOpt = response.data.data[0];
                // animal type list
                let list_1 = allOpt.animalType.map((row, index) => {
                    return(
                        <option key={index} value={row.animal_type_id}> {row.name} </option> 
                    )
                });
                setAnimalTypeOpt(list_1);

                // breed Type list
                let list_2 = allOpt.breedType.map((row, index) => {
                    return(
                        <option key={index} value={row.breed_type_id}> {row.breed_type_name} </option> 
                    )
                });
                setBreedTypeOpt(list_2);

                // milking Time list
                let list_3 = allOpt.milkingTime.map((row, index) => {
                    return(
                        <option key={index} value={row.milking_time_id}> {row.name} </option> 
                    )
                });
                setMilkingTimeOpt(list_3);

                // milking Time list
                let list_4 = allOpt.milkingMethod.map((row, index) => {
                    return(
                        <option key={index} value={row.milking_method_id}> {row.name} </option> 
                    )
                });
                setMilkingMethodOpt(list_4);

                // milking Time list
                let list_5 = allOpt.feedingPractices.map((row, index) => {
                    return(
                        <option key={index} value={row.feeding_practices_id}> {row.name} </option> 
                    )
                });
                setFeedingPracticesOpt(list_5);
            }
        })
        .catch(error => {
            let notify = notification({ message: "Animals Option not found", type: 'error' });
            notify();
        }) 
    }

    const get_age = (e) => {
        setAnimalType(e.target.value);
        let postData = {"animal_type_id": e.target.value};
        axios({
            method: 'post',
            url: mainUrl + 'master/getAnimalAge',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        }) 
        .then(function (response) {
            if (response.data.status === 1) {

                let list = response.data.data.map((row, index) => {
                    return(
                        <option key={index} value={row.age_id}> {row.name} </option> 
                    )
                });
                setAnimalAgeOpt(list);
            }
        })
        .catch(error => {
            let notify = notification({ message: "Animals age not found!", type: 'error' });
            notify();
        }) 
    }

    const get_breed = (e) => {
        setBreedType(e.target.value);
        let postData = {"animal_type_id": animalType, "breed_type_id": e.target.value};
        axios({
            method: 'post',
            url: mainUrl + 'master/getAnimalBreed',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        }) 
        .then(function (response) {
            if (response.data.status === 1) {

                let list = response.data.data.map((row, index) => {
                    return(
                        <option key={index} value={row.breed_id}> {row.name} </option> 
                    )
                });
                setBreedOpt(list);
            }
        })
        .catch(error => {
            let notify = notification({ message: "Animals age not found!", type: 'error' });
            notify();
        }) 
    }

    const get_animal_details = () => {
        let postData = {"user_id": JSON.parse(sessionStorage.getItem('user_detail')).farmer_code }
        axios({
            method: 'post',
            url: mainUrl + 'activity/myAnimalView',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        }) 
        .then(function (response) {
            if (response.data.status === 1) {
                setTotalAnimal(response.data.data.length);
                let list = response.data.data.map((row, index) => {
                    
                    return(
                        <Col xs={12} md={4} key={index}>
                            <div className="product mt-4 mb-2">
                                <Row>
                                    <Col xs={12} className="position-relative">
                                        <div className='animalImg'>
                                            <img className='img-fluid' width="40px" height="40px" src={require('../../../assets/img/cow.png')} alt="animal img" />
                                        </div>

                                        <h4 className='text-end'>{row.animal_type}</h4>
                                        <div className='d-flex justify-content-between fs-12 text-muted'>
                                            <div><b>Age:</b></div>
                                            <div>{row.animal_age}</div>
                                        </div>
                                        <div className='d-flex justify-content-between fs-12 text-muted'>
                                            <div><b>Type:</b></div>
                                            <div> {row.breed_type} </div>
                                        </div>
                                        <div className='d-flex justify-content-between fs-12 text-muted'>
                                            <div><b>Breed:</b></div>
                                            <div> {row.breed_name} </div>
                                        </div>
                                        <div className='d-flex justify-content-between fs-12 text-muted'>
                                            <div><b>Milking:</b></div>
                                            <div> {row.milking} Ltrs </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    )
                })

                setAnimalList(list);
            }else{
                let notify = notification({ message: "Animals list not found", type: 'error' });
                notify();
            }
        })
        .catch(error => {
            let notify = notification({ message: "Animals not found", type: 'error' });
            notify();
        })    
    }

    const add_animal_handler = (e) => {
        e.preventDefault();
        setLoading(true);
        let postData = {
            "user_id": JSON.parse(sessionStorage.getItem('user_detail')).farmer_code,
            "type": animalType,
            "age": animalAge,
            "breed_type": breedType,
            "breed": breed,
            "milking": milking,
            "milking_time": milkingTime,
            "milking_method": milkingMethod,
            "feeding": feedingPractices,
            "concentrate_fed": concentrateFed,
            "ai": ai,
            "vaccination": vaccination,
            "use_dung": dung,
            "methane": methane,
        };
        console.log(postData);
        axios({
            method: 'post',
            url: mainUrl + 'activity/addNewAnimal',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                setLoading(false);
                if (response.data.status === 1) {
                    get_animal_details();
                    setAnimalType("");
                    setAnimalAge("");
                    setBreedType("");
                    setBreed("");
                    setMilking("");
                    setMilkingTime("");
                    setMilkingMethod("");
                    setFeedingPractices(0);
                    setConcentrateFed(0);
                    setAi(0);
                    setVaccination(0);
                    setDung(0);
                    setMethane('');
                    setModalAni(false);
                    let notify = notification({ message: response.data.message, type: 'success' });
                    notify();
                } else {
                    let notify = notification({ message: response.data.message, type: 'error' });
                    notify();
                }
            })
            .catch(error => {
                setLoading(false);
                let notify = notification({ message: "Sent failed!", type: 'error' });
                notify();
            })
    }

    return (
        <>
            <Col md="12">
                <Card>
                    <CardBody>
                        <Row className='mb-2'>
                            <Col xs={12} md={3}>
                                <h5> My Animals </h5> 
                            </Col>

                            <Col xs={12} md={9}>
                                {/*<Stack direction="row" spacing={1}>*/}
                                    <Row>
                                        <Col xs={6} md={3} className="mb-2">
                                            <Chip
                                                avatar={<Avatar className='batches' sx={{ bgcolor: deepOrange[500] }}>{totalAnimal}</Avatar>}
                                                label="Total Animals"
                                                variant="outlined"
                                            />
                                        </Col>
                                        <Col xs={6} md={3} className="mb-2">
                                            <Chip
                                                avatar={<Avatar className='batches' sx={{ bgcolor: deepOrange[500] }}>0</Avatar>}
                                                label="Milch Animals"
                                                variant="outlined"
                                            />
                                        </Col>
                                        <Col xs={6} md={4} className="mb-2">
                                            <Chip
                                                avatar={<Avatar className='batches' sx={{ bgcolor: deepOrange[500] }}>0</Avatar>}
                                                label="Bovine Milch Animals"
                                                variant="outlined"
                                            />
                                        </Col>
                                    </Row> 
                            </Col>
                        </Row>
                        <Row>
                            {animalList}
                            <Col xs={6} md={2}>
                                <div className="product mt-4 cursor" onClick={()=>setModalAni(true)}>
                                    <div className='text-center'>
                                        <FaPlusCircle size={40} color="#2473cb" />
                                        <div>Add New Animal</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>

            <Modal
                centered
                scrollable
                size="md"
                isOpen={modalAni}
                toggle={() => setModalAni(!modalAni)}
            >
                <ModalHeader toggle={() => setModalAni(!modalAni)}>
                    Add New Animal
                </ModalHeader>
                <ModalBody className='bg-light'>
                    <Form onSubmit={add_animal_handler}>
                        <Row>
                            <Col md={6} className="mb-2">
                                <Label for="animalType"> Animal Type </Label>
                                <Input id="animalType" bsSize="sm" type="select" value={animalType} onChange={get_age} required>
                                    <option value=""> --Select Animal Type-- </option>
                                    {animalTypeOpt}
                                </Input>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="animalAge"> Age </Label>
                                <Input id="animalAge" bsSize="sm" type="select" value={animalAge} onChange={(e)=> setAnimalAge(e.target.value)} required>
                                    <option value=""> --Select Age-- </option>
                                    {animalAgeOpt}
                                </Input>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="breedType"> Type </Label>
                                <Input id="breedType" bsSize="sm" type="select" value={breedType} onChange={get_breed} required>
                                    <option value=""> --Select Type-- </option>
                                    {breedTypeOpt}
                                </Input>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="breed"> Breed </Label>
                                <Input id="breed" bsSize="sm" type="select" value={breed} onChange={(e)=> setBreed(e.target.value)} required>
                                    <option value=""> --Select Breed-- </option>
                                    {breedOpt}
                                </Input>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="milking"> Milking </Label>
                                <InputGroup size="sm">
                                    <Input id="milking" type='text' maxLength={3}value={milking} onChange={(e) => setMilking(e.target.value)} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} bsSize="sm" required />
                                    <InputGroupText> Litres </InputGroupText>
                                </InputGroup>
                                
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="milkingTime"> Time of Milking </Label>
                                <Input id="milkingTime" bsSize="sm" type="select" value={milkingTime} onChange={(e)=> setMilkingTime(e.target.value)} required>
                                    <option value=""> --Select Timing-- </option>
                                    {milkingTimeOpt}
                                </Input>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="milkingMethod"> Method of Milking </Label>
                                <Input id="milkingMethod" bsSize="sm" type="select" value={milkingMethod} onChange={(e)=> setMilkingMethod(e.target.value)} required>
                                    <option value=""> --Select Method-- </option>
                                    {milkingMethodOpt}
                                </Input>
                            </Col>
                            
                            
                            <Col md={6} className="mb-2">
                                <Label for="milkingMethod"> Feeding Practices </Label>
                                <Input id="milkingMethod" bsSize="sm" type="select" value={feedingPractices} onChange={(e)=> setFeedingPractices(e.target.value)} required>
                                    <option value=""> --Select Method-- </option>
                                    {feedingPracticesOpt}
                                </Input>
                            </Col>
                            <Col md={12} className="mb-2">
                                <FormGroup row>
                                    <Label sm={8} for="concentrateFed"> Concentrate is fed to animals </Label>
                                    <Col sm={4}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography>No</Typography>
                                                <Switch onChange={()=>setConcentrateFed(!concentrateFed)} />
                                            <Typography>Yes</Typography>
                                        </Stack>
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col md={12} className="mb-2">
                                <FormGroup row>
                                    <Label sm={8} for="ai"> Whether AI is followed? </Label>
                                    <Col sm={4}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography>No</Typography>
                                                <Switch onChange={()=>setAi(!ai)} />
                                            <Typography>Yes</Typography>
                                        </Stack>
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col md={12} className="mb-2">
                                <FormGroup row>
                                    <Label sm={8} for="vaccination"> Vaccination </Label>
                                    <Col sm={4}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography>No</Typography>
                                                <Switch onChange={()=>setVaccination(!vaccination)} />
                                            <Typography>Yes</Typography>
                                        </Stack>
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col md={12} className="mb-2">
                                <FormGroup row>
                                    <Label sm={8} for="dung"> Do you use Dung as manure? </Label>
                                    <Col sm={4}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography>No</Typography>
                                                <Switch onChange={()=>setDung(!dung)} />
                                            <Typography>Yes</Typography>
                                        </Stack>
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col md={12} className="mb-2">
                                <FormGroup row>
                                    <Label for="email" sm={9} > How much manure gets produced? </Label>
                                    <Col sm={3}>
                                        <InputGroup size="sm">
                                            <Input id="milking" type='text' maxLength={3}value={methane} onChange={(e) => setMethane(e.target.value)} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} bsSize="sm" required />
                                            <InputGroupText> Kg </InputGroupText>
                                        </InputGroup>
                                    
                                    </Col>
                                </FormGroup>
                            </Col>
                           
                            <hr />
                            <Col md={12} className="mb-2">
                                <Button color='primary' size="sm" block disabled= {loading}> Submit {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>} </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default AnimalDetails;