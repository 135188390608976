import React, { useState, useEffect } from 'react';

import axios from 'axios';
import {mainUrl} from '../../../MainUrl';
import { notification } from '../../hocs/notification';

import { Row, Col, Card, CardBody, Button, Input, Form, Label, Spinner, Modal, ModalHeader, ModalBody, InputGroup, InputGroupText } from 'reactstrap';

import Select from 'react-select';
import DataTable from 'react-data-table-component';
import { FaPlusCircle } from 'react-icons/fa';

const ProductProduction = () => {

    const [loading, setLoading] = useState(false);
    const [modalProd, setModalProd] = useState(false);
    const [userDetail, setUserDetail] = useState(() => JSON.parse(sessionStorage.getItem('user_detail')));
    
    const [productList, setProductList] = useState('');
    
    const [productOpt, setProductOpt] = useState('');
    const [paymentOpt, setPaymentOpt] = useState('');
    const [outletOpt, setOutletOpt] = useState('');
    const [timingOpt, setTimingOpt] = useState('');

    const [selectedProd, setSelectedProd] = useState('');
    const [newProd, setNewProd] = useState('');
    const [prodUnit, setProdUnit] = useState('Kg');
    const [pYield, setPYield] = useState('');
    const [totalProduction, setTotalProduction] = useState('');
    const [price, setPrice] = useState('');
    const [saleChannel, setSaleChannel] = useState('');
    const [payment, setPayment] = useState('');
    const [cost, setCost] = useState('');
    const [outlet, setOutlet] = useState('');
    const [time, setTime] = useState('');
    const [distance, setDistance] = useState('');

    useEffect(()=> {
        get_all_options();
        get_product_production();
    },[]);
    
    const get_product_production = () => {
       let postData = {"user_id": JSON.parse(sessionStorage.getItem('user_detail')).farmer_code }
        axios({
            method: 'post',
            url: mainUrl + 'activity/farmerProductionView',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        }) 
        .then(function (response) {
            if(response.data.status === 1){
                
                let list = response.data.data.map(row => {
                    return(
                        {         
                            product_name: row.product_name,
                            total_production: row.total_production,
                            yield: row.yield, 
                            price: Number(row.price).toFixed(2), 
                            sale_channel: row.sale_channel,
                            payment_type: row.payment_type, 
                            cost: Number(row.cost).toFixed(2), 
                            outlet_type: row.outlet_type, 
                            delivery_time: row.delivery_time, 
                        }
                    )
                });
                setProductList(list);

            }else{
                let notify = notification({ message: response.data.message, type: 'error' });
                notify();
            }
        })
        .catch(error => {
            let notify = notification({ message: "No Data found!", type: 'error' });
            notify();
        })     
    }

    const get_all_options = () => {
        axios({
            method: 'post',
            url: mainUrl + 'master/getAddNewProdProductionOption',
            headers: { 'Content-Type': 'application/JSON', }
        }) 
        .then(function (response) {
            if (response.data.status === 1) {
                let allOpt = response.data.data[0];
                // Product list
                let list_1 = allOpt.product.map((row, index) => {
                    return { value: row.prod_id, label: row.prod_name, uom:row.uom_type }
                });
                setProductOpt(list_1);
                
                // outlet list
                let list_2 = allOpt.outlet.map((row, index) => {
                    return(
                        <option key={index} value={row.org_type_id}> {row.org_type_desc} </option> 
                    )
                });
                setOutletOpt(list_2);

                // Timing list
                let list_5 = allOpt.timing.map((row, index) => {
                    return(
                        <option key={index} value={row.milking_time_id}> {row.name} </option> 
                    )
                });
                setTimingOpt(list_5);
                
                // Payment Opt list
                let list_6 = allOpt.payment_opt.map((row, index) => {
                    return(
                        <option key={index} value={row.pay_id}> {row.name} </option> 
                    )
                });
                setPaymentOpt(list_6);
            }
        })
        .catch(error => {
            let notify = notification({ message: "All Option not found", type: 'error' });
            notify();
        }) 
    }


    const add_product_production_handler = (e) => {
        e.preventDefault();

        let postData = {
            "user_id": userDetail.farmer_code,
            "prod_id": newProd,
            "yield": pYield,
            "total_production": totalProduction,
            "price": price,
            "sale_channel": saleChannel,
            "payment_type": payment,
            "cost": cost,
            "outlet": outlet,
            "delivery_time": time,
            "distance": distance,
        };
        console.log(postData);
        axios({
            method: 'post',
            url: mainUrl + 'activity/addNewFarmerProduction',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                setLoading(false);
                if (response.data.status === 1) {
                    get_product_production();
                    setModalProd(false);
                    setNewProd('');
                    setPYield('');
                    setTotalProduction('');
                    setPrice('');
                    setSaleChannel('');
                    setPayment('');
                    setCost('');
                    setOutlet('');
                    setTime('');
                    setDistance('');
                    let notify = notification({ message: response.data.message, type: 'success' });
                    notify();
                } else {
                    let notify = notification({ message: response.data.message, type: 'error' });
                    notify();
                }
            })
            .catch(error => {
                setLoading(false);
                let notify = notification({ message: "Sent failed!", type: 'error' });
                notify();
            })
    }

    const change_product_handler = (opt) => {
        setSelectedProd(opt);
        setNewProd(opt.value);
        if(opt.uom === 1){
            setProdUnit("Kg");
        }else{
            setProdUnit("Litres"); 
        }
        
    }

    return (
        <>
            <Col md="12">
                <Card>
                    <CardBody>
                        <h5>Product Production Details</h5>
                        <Row>
                            <Col xs={12} className="mb-2">
                                <DataTable
                                    title=""
                                    columns={[
                                        {
                                            name: 'Product Name',
                                            selector: row => row.product_name,
                                            sortable: true,
                                        },
                                        {
                                            name: 'Daily Production',
                                            selector: row => row.total_production,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Daily Milk available for Sales',
                                            selector: row => row.yield,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Price',
                                            selector: row => row.price,
                                            sortable: false,
                                            wrap: true,
                                        },
                                        {
                                            name: 'Sale Channel',
                                            selector: row => row.sale_channel,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Payment Type',
                                            selector: row => row.payment_type,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Cost of labour to produce milk',
                                            selector: row => row.cost,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Outlet',
                                            selector: row => row.outlet_type,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Delivery Time',
                                            selector: row => row.delivery_time,
                                            sortable: false,
                                        },

                                    ]}
                                    data={productList}
                                    striped
                                    highlightOnHover
                                    progressPending={loading}

                                />
                            </Col>
                            
                            <Col xs={6} md={3}>
                                <div className="product h-100 w-100 d-flex justify-content-center align-items-center cursor" onClick={() => setModalProd(true)}>
                                    <div className='text-center'>
                                        <FaPlusCircle size={40} color="#2473cb" />
                                        <div>Add New Production</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>

            <Modal
                centered
                scrollable
                size="md"
                isOpen={modalProd}
                toggle={() => setModalProd(!modalProd)}
            >
                <ModalHeader toggle={() => setModalProd(!modalProd)}>
                    Add Production Details
                </ModalHeader>
                <ModalBody className='bg-light'>
                    <Form onSubmit={add_product_production_handler}>
                        <Row>
                            {/*
                            <Col xs={6} className="mb-2">
                                <Label for="product"> Product </Label>
                                <Input id="product" bsSize="sm" type="select" value={newProd} onChange={(e) => setNewProd(e.target.value)} required>
                                    <option value=""> --Select Product-- </option>
                                    {productOpt}
                                </Input>
                            </Col>
                            */}
                            <Col xs={12} className="mb-2">
                                <Label for="product"> Product </Label>
                                <Select
                                    styles={{ option: (base) => ({ ...base, fontSize: '0.8em', lineHeight: '1' }) }}
                                    value={selectedProd}
                                    onChange={change_product_handler}
                                    options={productOpt}
                                    maxMenuHeight={140}
                                />
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="yield"> Daily Milk available for Sales </Label>
                                <InputGroup size="sm">
                                    <Input id="yield" type='text' maxLength={3} value={pYield} onChange={(e) => setPYield(e.target.value)} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} bsSize="sm" required />
                                    <InputGroupText> {prodUnit} </InputGroupText>
                                </InputGroup>
                                
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="total_production"> Daily Production </Label>
                                <InputGroup size="sm">
                                <Input id="total_production" type='text' maxLength={3} value={totalProduction} onChange={(e) => setTotalProduction(e.target.value)} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} bsSize="sm" required />
                                    <InputGroupText> {prodUnit} </InputGroupText>
                                </InputGroup>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="price"> Price </Label>
                                <InputGroup size="sm">
                                    <InputGroupText> &#8377; </InputGroupText>
                                    <Input id="price" type='text' maxLength={3} value={price} onChange={(e) => setPrice(e.target.value)} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} bsSize="sm" required />
                                </InputGroup>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="sale_channel"> Sale Channel </Label>
                                <Input id="sale_channel" bsSize="sm" type="select" value={saleChannel} onChange={(e) => setSaleChannel(e.target.value)} required>
                                    <option value=""> --Select Sale Channel-- </option>
                                    <option value="1"> Direct-to- Consumer Outlets </option>
                                    <option value="2"> Local Traders Agents </option>
                                    <option value="3"> Commission Agents </option>
                                    <option value="4"> Processors </option>
                                    <option value="5"> Milk cooperatives </option>
                                    <option value="6"> Dairy </option>
                                    <option value="7"> Others </option>
                                </Input>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="payment"> Payment Mode </Label>
                                <Input id="payment" bsSize="sm" type="select" value={payment} onChange={(e) => setPayment(e.target.value)} required>
                                    <option value=""> --Select Payment Mode-- </option>
                                    {paymentOpt}
                                </Input>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="cost"> Cost of labour to produce milk </Label>
                                <InputGroup size="sm">
                                    <InputGroupText> &#8377; </InputGroupText>
                                    <Input id="cost" type='text' maxLength={3} value={cost} onChange={(e) => setCost(e.target.value)} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} bsSize="sm" required />
                                </InputGroup>
                            </Col>

                            <Col md={6} className="mb-2">
                                <Label for="Outlet"> Sales Outlet preferred </Label>
                                <Input id="Outlet" bsSize="sm" type="select" value={outlet} onChange={(e) => setOutlet(e.target.value)} required>
                                    <option value=""> --Select Outlet-- </option>
                                    {outletOpt}
                                </Input>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="time"> Delivery Time </Label>
                                <Input id="time" bsSize="sm" type="select" value={time} onChange={(e) => setTime(e.target.value)} required>
                                    <option value=""> --Select Time-- </option>
                                    {timingOpt}
                                </Input>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="distance"> Distance ready to deliver [km]</Label>
                                <InputGroup size="sm">
                                    <Input id="distance" type='text' maxLength={3} value={distance} onChange={(e) => setDistance(e.target.value)} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} bsSize="sm" required />
                                    <InputGroupText> Km </InputGroupText>
                                </InputGroup>
                            </Col>
                            
                            <hr />
                            <Col md={12} className="mb-2">
                                <Button color='primary' size="sm" block disabled={loading}> Submit {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>} </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ProductProduction;