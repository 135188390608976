import React, {useEffect} from 'react';
import Header from '../component/layout/Header';
import { Row, Col, Card, CardBody, CardTitle, } from 'reactstrap';
import Banner from '../component/utility/Banner';

import pic from '../assets/img/aboutpage.png';

const AboutUs = () => {
    
    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    return (
        <div>
            <Header />
            <Banner title="ABOUT US" page="1" />
            <Row className='p-5'>
                <Col md="4 mb-3">
                    <img className='img-fluid' src={pic} alt="image" />
                </Col>
                <Col md="8">
                    <CardTitle tag="h5">I-Milk</CardTitle>
                    <p>The I-MILK initiative seeks to connect institutional buyers of milk and milk products directly to the farmers groups.</p>
                    <p>The farmers' livelihood initiative enables a reputed establishment in the expanding and demanding HORECA business segment, to directly source its regular bulk milk and milk products requirement at a distinct price advantage, from a wide array of  rural dairy households  in proximity to your city with the added advantage of traceability of the source.</p>
                    <p>I-MILK is the flagship product of Infokul Grameen, a social purpose for profit start - up founded by industry veterans from IIM, IIT and IRMA, Anand, with experience in agri supply chain, market intelligence, analytics, IT, training, skilling and online global business.</p>
                    <p>The company is headquartered in Delhi and has presently commenced operation in Rajasthan,  Fresh milk sourced directly from farmers group is being delivered daily to hotels through a farm to fridge supply chain, at the hotel doorstep, always on time in select districts. The company plans to soon expand the I-MILK platform to other states of the country.</p>
                    <p>Apart from providing direct sourcing from local farmers group, the I-MILK platform gives tremendous value to bulk institutional buyers in planning their purchase of a daily use perishable item like milk.</p>
                    <p>Plans are afoot to replicate the I-MILK template to other perishable produce, thereby impacting the income and livelihood of lakhs of small stake farmers' households.</p>
                    <p>Contact I-MILK Team for complete solution for purchase of milk & milk products and other fresh perishables directly from source.</p>
                      
                </Col>
                
            </Row>
        </div>


    )
}

export default AboutUs;