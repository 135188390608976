import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from './../../hocs/notification';

import axios from 'axios';
import {mainUrl} from '../../../MainUrl';
import {MdMyLocation} from 'react-icons/md';
import { Row, Col, Form, Label, Input, Button, Card, CardBody, Spinner, InputGroup, InputGroupText } from 'reactstrap';

import {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,

} from 'react-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByLatLng } from 'react-google-places-autocomplete';

const FarmerRegistration = ({ back, ...props }) => {
    
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);

    const [type, setType] = useState(1);

    const [fpoName, setFpoName] = useState('');
    const [member, setMember] = useState(false);
    const [fName, setFName] = useState('');
    const [designation, setDesignation] = useState('');
    const [gender, setGender] = useState('');
    const [mobileNo, setMobileNo] = useState(sessionStorage.getItem('r_mobile_no'));
    const [education, setEducation] = useState("");
    const [state, setState] = useState("");
    const [district, setDistrict] = useState("");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");
    const [pincode, setPincode] = useState("");
    const [lat, setLat] = useState("0");
    const [lng, setLng] = useState("0");

    useEffect(()=>{
        get_current_location();
    },[])

    const handleChange = address => {
        setAddress(address);
    };

    const handleSelect = async value => {
        const res = await geocodeByAddress(value);
        const latLng = await getLatLng(res[0]);
        console.log(res);
        setLat(latLng.lat);
        setLng(latLng.lng);

        setAddress(res[0].formatted_address);

        res[0].address_components.map(row => {

            if (row.types.indexOf("administrative_area_level_1") > -1) {
                setState(row.long_name);
            }
            if (row.types.indexOf("administrative_area_level_3") > -1) {
                setDistrict(row.long_name);
            }
            if (row.types.indexOf("locality") > -1) {
                setCity(row.long_name);
            }
            if (row.types.indexOf("postal_code") > -1) {
                setPincode(row.long_name);
            }
        })



    };

    const get_current_location = () => {
        const location = window.navigator && window.navigator.geolocation;
        if (location) {
            location.getCurrentPosition((position) => {
                setLat(position.coords.latitude);
                setLng(position.coords.longitude);
                //get address
                geocodeByLatLng({ lat: position.coords.latitude, lng: position.coords.longitude })
                    .then(res => {
                        setAddress(res[0].formatted_address);

                        res[0].address_components.map(row => {

                            if (row.types.indexOf("administrative_area_level_1") > -1) {
                                setState(row.long_name);
                            }
                            if (row.types.indexOf("administrative_area_level_3") > -1) {
                                setDistrict(row.long_name);
                            }
                            if (row.types.indexOf("locality") > -1) {
                                setCity(row.long_name);
                            }
                            if (row.types.indexOf("postal_code") > -1) {
                                setPincode(row.long_name);
                            }
                        })
                    })
                    .catch(error => console.error(error));
            }, (error) => {
                setLat(0);
                setLng(0);
            });
        }

    }

    const register_handler = () => {
        let flag = true;
       
        if (fpoName === "") {
            flag = false;
            let notify = notification({ message: "Please add FPO Name. ", type: 'warn' });
            notify();
        }
        if (fName === "") {
            flag = false;
            let notify = notification({ message: "Please add Contact Person Name. ", type: 'warn' });
            notify();
        }
        if (gender === "") {
            flag = false;
            let notify = notification({ message: "Please select Gender. ", type: 'warn' });
            notify();
        }
        if (education === "") {
            flag = false;
            let notify = notification({ message: "Please select education. ", type: 'warn' });
            notify();
        }
        if (address === "" || lat === "0" || lng === "0") {
            flag = false;
            let notify = notification({ message: "Please select Address. ", type: 'warn' });
            notify();
        }

        if(flag){

            setLoading(true);
            let postData = [{
                "fpo_name": fpoName,
                "contact_person": fName,
                "gender": gender,
                "mobile_no": mobileNo,
                "photo": "farmer.png",
                "education": education,
                "designation": designation,
                "state": state,
                "district": district,
                "city": city,
                "address": address,
                "pincode": pincode,
                "lat": lat,
                "lng": lng,
            }];
    
            axios({
                method: 'post',
                url: mainUrl + 'user/fpoRegistration',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    if(response.data[0].status === 1){
                        let notify = notification({ message: response.data[0].message, type: 'success' });
                        notify();
                        sessionStorage.setItem('user_detail', JSON.stringify(response.data[0].data));
                        sessionStorage.setItem('user_id', response.data[0].data.user_code);
                        sessionStorage.setItem('currId', '11');
                        navigate('/productMapFarmer');
                    }else{
                        let notify = notification({ message: response.data[0].message, type: 'error' });
                        notify();
                        setLoading(false);
                    }
    
                }).catch(error => {
                    setLoading(false);
                    let notify = notification({ message: "Failed! Try Again", type: 'error' });
                    notify();
                })
        }
  
    }

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col xs="12">
                        <div className=''>
                            <h4 className='text-primary mt-2 mb-3'>FPO Registration</h4>
                        </div>
                        {step === 1 &&
                            <Row>
                                <h6>Basic Information</h6>
                                <Col md="6" className='mb-2'>
                                    <Label for="fpoName">Name of FPO</Label>
                                    <Input id="fpoName" bsSize="sm" type="text" value={fpoName} onChange={(e) => setFpoName(e.target.value)} placeholder="FPO Name" />
                                </Col>
                                <Col md="6" className='mb-2'>
                                    <Label for="fName">Contact Person Name</Label>
                                    <Input id="fName" bsSize="sm" type="text" value={fName} onChange={(e) => setFName(e.target.value)} placeholder="Your Name" />
                                </Col>
                                <Col md="6" className='mb-2'>
                                    <Label for="designation">Designation</Label>
                                    <Input id="designation" bsSize="sm" type="select" value={designation} onChange={(e) => setDesignation(e.target.value)} required>
                                        <option value="0"> --Select Designation--</option>
                                        <option value="1"> Director </option>
                                        <option value="2"> Manager </option>
                                    </Input>
                                    
                                </Col>
                                <Col md="6" className='mb-2'>
                                    <Label for="gender">Gender</Label>
                                    <Input id="gender" bsSize="sm" type="select" value={gender} onChange={(e) => setGender(e.target.value)} required>
                                        <option value="0"> --Select Gender--</option>
                                        <option value="1"> Male </option>
                                        <option value="2"> Female </option>
                                    </Input>
                                </Col>
                                <Col md={6} className="mb-2">
                                    <Label for="mobileNo"> Mobile Number</Label>
                                    <InputGroup size="sm">
                                        <InputGroupText> +91 </InputGroupText>
                                        <Input id="mobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} placeholder="Your Mobile Number" readOnly />
                                    </InputGroup>
                                    
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Label for="education"> {type === 1 ? "Chief Wage Earner" : "Chief Functionary"} : Education </Label>
                                    <Input id="education" bsSize="sm" type="select" value={education} onChange={(e) => setEducation(e.target.value)} required>
                                        <option value=""> --Select Education--</option>
                                        <option value="101"> Illiterate </option>
                                        <option value="102"> School-Upto 4 year </option>
                                        <option value="103"> School-5 to 9 year </option>
                                        <option value="104"> SSC / HS </option>
                                        <option value="105"> Graduate / Post Graduate: General </option>
                                        <option value="106"> Graduate / Post Graduate: Professional </option>
                                    </Input>
                                </Col>
                                <h6>Address Information</h6>
                                
                                <Col md={12} className="mb-2">
                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={handleChange}
                                            onSelect={handleSelect}
                                            searchOptions= {{componentRestrictions: { country: "in" },}}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: 'Search your address and select',
                                                            className: 'location-search-input form-control',
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map(suggestion => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#A7C7EA', cursor: 'pointer', padding: '5px' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '5px' };
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style,
                                                                    })}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </Col>
                                    <Col md={12} className="mb-2">
                                        <span className='text-primary cursor' onClick={get_current_location}>
                                            <MdMyLocation color="#2473cb" size={30} />
                                            <span className='ms-2'><b>Use my current Location</b></span>
                                        </span>
                                    </Col>

                                <Col xs={12} className="d-flex justify-content-between mt-3">
                                    <Button color='secondary' size="sm" onClick={back}>Back</Button>
                                    <Button color='primary' size="sm" onClick={register_handler} disabled={loading}> Register  {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default FarmerRegistration;