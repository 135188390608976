import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderFarmer from '../../component/layout/HeaderFarmer';
import FooterSmall from '../../component/layout/FooterSmall';
import Page from '../../component/layout/Page';

import { notification } from '../../component/hocs/notification';
import { FaPlusCircle } from 'react-icons/fa';
import DataTable from 'react-data-table-component';

import axios from 'axios';
import { mainUrl } from '../../MainUrl';
import FarmerDailyCard from '../../component/utility/farmer/FarmerDailyCard';
import * as XLSX from 'xlsx/xlsx.mjs';
import { FaFileDownload } from 'react-icons/fa';

import { getTodayDate, getDateFormat } from '../../component/hocs/formateDate';

import { Row, Col, Input, Button, Card, CardBody, Spinner, FormGroup, CardFooter, Label } from 'reactstrap';
import ExcelExport from './../../component/utility/ExcelExport';

const AddDailyProduction = () => {

    const navigate = useNavigate();
    const [mode, setMode] = useState(1);
    const [loading, setLoading] = useState(false);

    const [selectionDate, setselectionDate] = useState("0");
    const [toDate, setToDate] = useState("");
    const [frmDate, setFrmDate] = useState("");

    const [tableData, setTableData] = useState("");
    const [tableDataExcel, setTableDataExcel] = useState([]);

    const [farmerList, setFarmerList] = useState("");
    const [farmerListDate, setFarmerListDate] = useState("");
    const [farmerListExcel, setFarmerListExcel] = useState([]);

    const [pdfFile, setPdfFile] = useState("");

    const [farmerListExcelUpload, setFarmerListExcelUpload] = useState("");


    useEffect(() => {
        window.scrollTo(0, 0);
        get_excel_farmer_list();
        init_load();
    }, []);

    const submit_handler = (e) => {
        e.preventDefault();
        setLoading(true);

        const postData = { "to_date": toDate, "frm_date": frmDate, "user_id": sessionStorage.getItem('user_id') };
        axios.post(mainUrl + 'activity/getDailyProduction', postData)
            .then(function (response) {
                if (response.data.status === 1) {
                    let result = response.data.data.allData;

                    const grouped = groupBy(result, pet => pet.prod_date);

                    let list = Array.from(grouped).map((row, index) => {
                        let qt = 0;
                        let tot = 0;
                        row[1].map(r2 => {
                            qt = qt + Number(r2.qty);
                            tot = tot + Number(r2.total);

                        })
                        return (
                            {
                                sl: index + 1,
                                farmer: row[1].length,
                                date: row[0].split(" ")[0].split('-').reverse().join('-'),
                                qty: <span> {qt} Ltrs</span>,
                                total: <span> &#8377; {Number(tot).toFixed(2)}</span>,
                                action: <Button color='primary' size="sm" onClick={() => get_detail_handler(row[1], row[0].split(" ")[0].split('-').reverse().join('-'))} > Detail </Button>
                            }
                        )
                    })
                    setTableData(list);
                    //Excel export data 
                    let e_list = Array.from(grouped).map((row, index) => {
                        let qt = 0;
                        let tot = 0;
                        row[1].map(r2 => {
                            qt = qt + Number(r2.qty);
                            tot = tot + Number(r2.total);
                        })
                        return (
                            {
                                "SL No": index + 1,
                                "Production Date": row[0].split(" ")[0].split('-').reverse().join('-'),
                                "Total_Farmer": row[1].length,
                                "Quantity (In Liters)": qt,
                                "Total Amount": Number(tot).toFixed(2),
                            }
                        )
                    })
                    setTableDataExcel(e_list);
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const groupBy = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    const get_detail_handler = (result, date) => {
        //console.log(result);
        setFarmerListDate(date);
        let farmer_list = result.map((row, index) => {
            return (
                <FarmerDailyCard key={index} data={row} />
            )
        })
        setFarmerList(farmer_list);
        // Excel export data
        let e_list = result.map((row, index) => {
            return{
                "Sl. No.": index + 1,
                "Farmer Name": row.farmer_name,
                "Registration No": row.farmer_code,
                "Date": row.reg_date,
                "Milk Type": row.milk_type,
                "Quantity (In Liters)": row.qty,
                "FAT": row.fat,
                "SNF": row.snf,
                "CLR": row.clr,
                "Density": row.density,
                "Lactose": row.lactose,
                "Salts": row.salts,
                "Protein": row.protein,
                "Temp": row.temp,
                "Added Water": row.added_water,
                "Freezing Point": row.freezing_point,
                "Rate/Kg": row.rate,
                "Total Amount": row.total,
            }
        })
        setFarmerListExcel(e_list);

        setMode(3);
    }

    const upload_excel = (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append("pic", pdfFile[0]);
        formData.append("fpo_code", sessionStorage.getItem('user_id'));

        axios.post(mainUrl + 'user/productionExcelUpload', formData)
            .then(function (response) {
                if (response.data.status === 1) {
                    let notify = notification({ message: response.data.message, type: 'success' });
                    notify();
                    setTimeout(() => {
                        init_load();
                        setMode(1);
                    }, 2000);
                    
                }else{
                    let notify = notification({ message: response.data.message, type: 'error' });
                    notify();
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_excel_farmer_list = () => {
        let postData = { "fpo_code": sessionStorage.getItem('user_id') }
        axios.post(mainUrl + 'user/getFarmerList', postData)
            .then(function (response) {
                let list = response.data.data.map(row => {
                    return {
                        'Farmer ID': row.farmer_code,
                        'Name': row.name,
                        'Milk Qty': 0,
                        'FAT': 0,
                        'SNF': 0,
                        'CLR': 0,
                        'Density': 0,
                        'Lactose': 0,
                        'Salts': 0,
                        'Protein': 0,
                        'Temp': 0,
                        'Added Water': 0,
                        'Freezing Point': 0,
                        'Milk Type': '-',
                        'Rate / kg': 0,
                        'Rate / LT': 0,
                        'Total': 0,
                        'CAN No': 0,
                        'Farmer No': 0,
                    }
                });

                setFarmerListExcelUpload(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const download_excel_handler = () => {
        if (farmerListExcelUpload.length > 0) {
            let wb = XLSX.utils.book_new(),
                ws = XLSX.utils.json_to_sheet(farmerListExcelUpload);

            XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");

            XLSX.writeFile(wb, "daily_production_excel.xlsx");
        }
    }

    const get_selection_date_handler = (e) => {
        setselectionDate(e.target.value);

        if (e.target.value === '101') {
            setToDate(getTodayDate());
            setFrmDate(getTodayDate());
        } else if (e.target.value === '102') {
            let curr = new Date; // get current date
            console.log(curr.getDate() - curr.getDay());
            let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            let last = first + 6; // last day is the first day + 6

            let firstday = getDateFormat(new Date(curr.setDate(first)));
            let lastday = getDateFormat(new Date(curr.setDate(last)));
            //
            setToDate(firstday);
            setFrmDate(lastday);
        }else if(e.target.value === '103'){
            let curr = new Date();
            let firstDay = new Date(curr.getFullYear(), curr.getMonth(), 1);
            let lastDay = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);

            setToDate(getDateFormat(firstDay));
            setFrmDate(getDateFormat(lastDay));
        }else if(e.target.value === '104'){
            var curr = new Date();
            var firstDay = new Date(curr.getFullYear() - (curr.getMonth() > 0 ? 0 : 1), (curr.getMonth() - 1 + 12) % 12, 1);
            var lastDay = new Date(curr.getFullYear(), curr.getMonth(), 0);
            
            setToDate(getDateFormat(firstDay));
            setFrmDate(getDateFormat(lastDay));
        }
    }

    const init_load = () => {
        setselectionDate("101");
        setToDate(getTodayDate());
        setFrmDate(getTodayDate());
        
        const postData = { "to_date": getTodayDate(), "frm_date": getTodayDate(), "user_id": sessionStorage.getItem('user_id') };
        axios.post(mainUrl + 'activity/getDailyProduction', postData)
            .then(function (response) {
                if (response.data.status === 1) {
                    let result = response.data.data.allData;

                    const grouped = groupBy(result, pet => pet.prod_date);

                    let list = Array.from(grouped).map((row, index) => {
                        let qt = 0;
                        let tot = 0;
                        row[1].map(r2 => {
                            qt = qt + Number(r2.qty);
                            tot = tot + Number(r2.total);

                        })
                        return (
                            {
                                sl: index + 1,
                                farmer: row[1].length,
                                date: row[0].split(" ")[0].split('-').reverse().join('-'),
                                qty: <span> {qt} Ltrs</span>,
                                total: <span> &#8377; {Number(tot).toFixed(2)}</span>,
                                action: <Button color='primary' size="sm" onClick={() => get_detail_handler(row[1], row[0].split(" ")[0].split('-').reverse().join('-'))} > Detail </Button>
                            }
                        )
                    })
                    setTableData(list);
                    //Excel export data 
                    let e_list = Array.from(grouped).map((row, index) => {
                        let qt = 0;
                        let tot = 0;
                        row[1].map(r2 => {
                            qt = qt + Number(r2.qty);
                            tot = tot + Number(r2.total);
                        })
                        return (
                            {
                                "SL No": index + 1,
                                "Production Date": row[0].split(" ")[0].split('-').reverse().join('-'),
                                "Total_Farmer": row[1].length,
                                "Quantity (In Liters)": qt,
                                "Total Amount": Number(tot).toFixed(2),
                            }
                        )
                    })
                    setTableDataExcel(e_list);
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    } 

    return (
        <div>
            <HeaderFarmer />
            <Page>
                <Row className='p-3'>
                    {mode === 1 &&
                        <>
                            <Col className="mb-3" md={12}>
                                <div className='profileName mb-3'>
                                    Daily Production Data
                                </div>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup row>
                                                    <Label md={2} for="selectionDate"> <b>Select Option</b> </Label>
                                                    <Col md={4}>
                                                        <Input id="selectionDate" bsSize="sm" type="select" value={selectionDate} onChange={get_selection_date_handler} required>
                                                            <option value=""> --Select Option--</option>
                                                            <option value="101"> Today </option>
                                                            <option value="102"> This Week </option>
                                                            <option value="103"> This Month </option>
                                                            <option value="104"> Last Month </option>
                                                        </Input>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Button className='mb-2' color='primary' size="sm" onClick={submit_handler} > Search </Button>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} className="text-end">
                                                { (tableDataExcel && tableDataExcel.length > 0) &&
                                                    <ExcelExport data={tableDataExcel} name="milk_production" />
                                                }
                                                <Button color='primary' size="sm" className='ms-3' onClick={() => setMode(2)} ><FaPlusCircle size={20} /> Add Milk Data </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={12}>
                                <Card>
                                    <CardBody>
                                        <DataTable
                                            title=""
                                            columns={[
                                                {
                                                    name: 'Date',
                                                    selector: row => row.date,
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Total Farmer',
                                                    selector: row => row.farmer,
                                                    sortable: false,
                                                },
                                                {
                                                    name: 'Milk Quantity',
                                                    selector: row => row.qty,
                                                    sortable: false,
                                                },
                                                {
                                                    name: 'Total Price',
                                                    selector: row => row.total,
                                                    sortable: false,
                                                    wrap: true,
                                                },
                                                {
                                                    name: 'Action',
                                                    selector: row => row.action,
                                                    sortable: false,
                                                },
                                            ]}
                                            data={tableData}
                                            striped
                                            highlightOnHover
                                            progressPending={loading}

                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </>
                    }

                    {mode === 3 &&
                        <Row>
                            <Col xs={12} className='mb-3'>
                                <div className='profileName d-flex justify-content-between'>
                                    Farmer wise Details (Date: {farmerListDate})
                                    <div className='text-end'>
                                        { (farmerListExcel && farmerListExcel.length > 0) &&
                                            <ExcelExport data={farmerListExcel} name="production_details" />
                                        }
                                        <Button className='ms-3' color='secondary' size="sm" onClick={() => setMode(1)} > Back </Button>
                                    </div>
                                </div>
                            </Col>
                            {farmerList}
                        </Row>
                    }
                    {mode === 2 &&
                        <Col md={{
                            offset: 2,
                            order: 2,
                            size: 8
                        }}>

                            <Row>
                                <Col xs={12} className='mb-3'>
                                    <div className='profileName'>
                                        Add Daily Production
                                    </div>
                                </Col>
                                <Col xs={12} className='mb-3'>
                                    <Card>
                                        <CardBody>
                                            <FormGroup row>
                                                <Col sm={6}>
                                                    <Input bsSize="sm" type="file" onChange={(e) => setPdfFile(e.target.files)} />
                                                    <div className='mt-2'>
                                                        <small className='text-muted fs-10'>
                                                            <i>*Select Excel file only. Download sample excel file
                                                                <Button className='ms-2' color='secondary' size="sm" onClick={download_excel_handler}><FaFileDownload /></Button></i>
                                                        </small>
                                                    </div>
                                                </Col>
                                                <Col sm={4}>
                                                    <Button color='primary' size="sm" disabled={loading} onClick={upload_excel}
                                                    > Upload {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                        <CardFooter className='text-end'>
                                            <Button color='secondary' size="sm" onClick={() => setMode(1)} > Back </Button>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>

            </Page>
            <FooterSmall />
        </div>


    )
}

export default AddDailyProduction;