import React, { useState, useEffect } from 'react';

import axios from 'axios';
import {mainUrl} from '../../../MainUrl';
import { notification } from '../../hocs/notification';

import { Row, Col, Card, CardBody, Button, Input, Form, Label, Spinner, Modal, ModalHeader, ModalBody, InputGroup, InputGroupText } from 'reactstrap';

import DataTable from 'react-data-table-component';
import { FaPlusCircle } from 'react-icons/fa';
import Select from 'react-select';

const ProductRequirements = () => {

    const [loading, setLoading] = useState(false);
    const [modalProd, setModalProd] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [userDetail, setUserDetail] = useState(() => JSON.parse(sessionStorage.getItem('user_detail')));
    
    const [productList, setProductList] = useState('');

    const [productOpt, setProductOpt] = useState('');
    const [packagingOpt, setPackagingOpt] = useState('');
    const [sourceOpt, setSourceOpt] = useState('');
    const [animalTypeOpt, setAnimalTypeOpt] = useState('');
    const [timingOpt, setTimingOpt] = useState('');
    const [paymentOpt, setPaymentOpt] = useState('');
    const [frequencyOpt, setFrequencyOpt] = useState('');

    const [newProd, setNewProd] = useState('');
    const [selectedProd, setSelectedProd] = useState('');
    const [requirement, setRequirement] = useState('');
    const [price, setPrice] = useState('');
    const [packaging, setPackaging] = useState('');
    const [source, setSource] = useState('');
    const [type, setType] = useState('');
    const [quality, setQuality] = useState('');
    const [time, setTime] = useState('');
    const [payment, setPayment] = useState('');
    const [frequency, setFrequency] = useState('');

    const [prodUnit, setProdUnit] = useState('');

    useEffect(()=> {
        get_product_requirement();
        get_all_options();
    },[]);
    
    const get_product_requirement = () => {
        let postData = {"user_id": JSON.parse(sessionStorage.getItem('user_detail')).buyer_code }
        axios({
            method: 'post',
            url: mainUrl + 'activity/myRequirementView',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        }) 
        .then(function (response) {
            if(response.data.status === 1){
                
                let list = response.data.data.map(row => {
                    let quality = '-';
                    if(row.quality == 1){
                        quality = "Full Cream"
                    }else if(row.quality == 2){
                        quality = "Toned"
                    }else if(row.quality == 3){
                        quality = "Double Toned"
                    }
                    return(
                        {
                            prod: row.product_name,
                            requirement: row.req,
                            product_unit: row.product_unit,
                            price: Number(row.price).toFixed(2), 
                            packaging: row.packaging_type,
                            source: row.source_name, 
                            type: row.animal_type, 
                            quality: quality, 
                            time: row.timing,
                            payment: row.payment_opt, 
                            frequency: row.frequency_type
                        }
                    )
                });
                setProductList(list);

            }else{
                let notify = notification({ message: response.data.message, type: 'error' });
                notify();
            }
        })
        .catch(error => {
            let notify = notification({ message: "All Option not found", type: 'error' });
            notify();
        })     
    }

    const get_all_options = () => {
        axios({
            method: 'post',
            url: mainUrl + 'master/getAddNewProdReqOption',
            headers: { 'Content-Type': 'application/JSON', }
        }) 
        .then(function (response) {
            if (response.data.status === 1) {
                let allOpt = response.data.data[0];
                // Product list
                let list_1 = allOpt.product.map((row, index) => {
                    return { value: row.prod_id, label: row.prod_name, uom:row.uom_type }
                });
                setProductOpt(list_1);

                // Packaging list
                let list_2 = allOpt.packaging.map((row, index) => {
                    return(
                        <option key={index} value={row.packaging_id}> {row.name} </option> 
                    )
                });
                setPackagingOpt(list_2);

                // Source list
                let list_3 = allOpt.source.map((row, index) => {
                    return(
                        <option key={index} value={row.source_id}> {row.name} </option> 
                    )
                });
                setSourceOpt(list_3);

                // Animal Type list
                let list_4 = allOpt.animalType.map((row, index) => {
                    return(
                        <option key={index} value={row.animal_type_id}> {row.name} </option> 
                    )
                });
                setAnimalTypeOpt(list_4);

                // Timing list
                let list_5 = allOpt.timing.map((row, index) => {
                    return(
                        <option key={index} value={row.milking_time_id}> {row.name} </option> 
                    )
                });
                setTimingOpt(list_5);
                
                // Payment Opt list
                let list_6 = allOpt.payment_opt.map((row, index) => {
                    return(
                        <option key={index} value={row.pay_id}> {row.name} </option> 
                    )
                });
                setPaymentOpt(list_6);
                
                // frequency list
                let list_7 = allOpt.frequency.map((row, index) => {
                    return(
                        <option key={index} value={row.frequency_id}> {row.name} </option> 
                    )
                });
                setFrequencyOpt(list_7);
            }
        })
        .catch(error => {
            let notify = notification({ message: "All Option not found", type: 'error' });
            notify();
        }) 
    }

    const add_product_req_handler = (e) => {
        e.preventDefault();

        let postData = {
            "user_id": userDetail.buyer_code,
            "prod_id": newProd,
            "req": requirement,
            "price": price,
            "packaging": packaging,
            "source": source,
            "type": type,
            "quality": quality,
            "time": time,
            "payment": payment,
            "frequency": frequency,
        };
        console.log(postData);
        axios({
            method: 'post',
            url: mainUrl + 'activity/addNewBuyerProductRequirement',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                setLoading(false);
                if (response.data.status === 1) {
                    setModalProd(false);
                    setNewProd('');
                    setRequirement('');
                    setPrice('');
                    setPackaging('');
                    setSource('');
                    setType('');
                    setQuality('');
                    setTime('');
                    setPayment('');
                    setFrequency('');
                    let notify = notification({ message: response.data.message, type: 'success' });
                    notify();
                    get_product_requirement();
                } else {
                    let notify = notification({ message: response.data.message, type: 'error' });
                    notify();
                }
            })
            .catch(error => {
                setLoading(false);
                let notify = notification({ message: "Sent failed!", type: 'error' });
                notify();
            })
    }

    const change_product_handler = (opt) => {
        setSelectedProd(opt);
        setNewProd(opt.value);
        setProdUnit(opt.uom);
        
    }


    return (
        <>
            <Col md="12">
                <Card>
                    <CardBody>
                        <h5>Product Requirements</h5>
                        <Row>
                            { productList.length > 0 &&
                                productList.map((row, index) => {
                                    return (
                                        <Col className='mb-3' xs={6} md={3} key={index}>
                                            <div className='mb-1 product'>
                                                <div className='text-center'>
                                                    <img className='img-fluid' width="40px" src={require('../../../assets/img/prod_5.jpg')} alt="img" />
                                                    <div> <b>{row.prod}</b> </div>
                                                </div>
                                                <div className='d-flex justify-content-between fs-12 text-muted'>
                                                    <div><b>Type:</b></div>
                                                    <div>{row.type}</div>
                                                </div>
                                                <div className='d-flex justify-content-between fs-12 text-muted'>
                                                    <div><b>Time:</b></div>
                                                    <div>{row.time}</div>
                                                </div>
                                                <div className='d-flex justify-content-between fs-12 text-muted'>
                                                    <div><b>Qty:</b></div>
                                                    <div>{row.requirement +" "+ row.product_unit}</div>
                                                </div>
                                                <div>
                                                    <Button className='mt-1' size='sm' color="primary" block onClick={() => setModalView(true)}> Details View </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })

                            }
                            
                            <Col xs={6} md={3}>
                                <div className="product h-100 w-100 d-flex justify-content-center align-items-center cursor" onClick={() => setModalProd(true)}>
                                    <div className='text-center'>
                                        <FaPlusCircle size={40} color="#2473cb" />
                                        <div>Add New Product Requirement</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>

            <Modal
                centered
                scrollable
                size="md"
                isOpen={modalProd}
                toggle={() => setModalProd(!modalProd)}
            >
                <ModalHeader toggle={() => setModalProd(!modalProd)}>
                    Add New Product Requirment
                </ModalHeader>
                <ModalBody className='bg-light'>
                    <Form onSubmit={add_product_req_handler}>
                        <Row>
                            {/*<Col md={12} className="mb-2">
                                <Label for="product"> Product </Label>
                                <Input id="product" bsSize="sm" type="select" value={newProd} onChange={(e) => setNewProd(e.target.value)} required>
                                    <option value=""> --Select Product-- </option>
                                    {productOpt}
                                </Input>
                        </Col>*/}
                            <Col xs={12} className="mb-2">
                                <Label for="product"> Product </Label>
                                <Select
                                    styles={{ option: (base) => ({ ...base, fontSize: '0.8em', lineHeight: '1' }) }}
                                    value={selectedProd}
                                    onChange={change_product_handler}
                                    options={productOpt}
                                    maxMenuHeight={140}
                                />
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="requirement"> Requirement/Day </Label>
                                <InputGroup size="sm">
                                    <Input id="requirement" type='text' maxLength={3} value={requirement} onChange={(e) => setRequirement(e.target.value)} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} bsSize="sm" required />
                                    <InputGroupText>{ prodUnit === 2 ? 'Litres' : 'Kg'} </InputGroupText>
                                </InputGroup>
                                
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="price"> Price/Unit </Label>
                                <InputGroup size="sm">
                                    <InputGroupText> &#8377; </InputGroupText>
                                    <Input id="price" type='text' maxLength={3} value={price} onChange={(e) => setPrice(e.target.value)} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} bsSize="sm" required />
                                </InputGroup>
                                
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="packaging"> Packaging </Label>
                                <Input id="packaging" bsSize="sm" type="select" value={packaging} onChange={(e) => setPackaging(e.target.value)} required>
                                    <option value=""> --Select Packaging-- </option>
                                    {packagingOpt}
                                </Input>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="source"> Source </Label>
                                <Input id="source" bsSize="sm" type="select" value={source} onChange={(e) => setSource(e.target.value)} required>
                                    <option value=""> --Select Source-- </option>
                                    {sourceOpt}
                                </Input>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="type"> Type </Label>
                                <Input id="type" bsSize="sm" type="select" value={type} onChange={(e) => setType(e.target.value)} required>
                                    <option value=""> --Select Type-- </option>
                                    {animalTypeOpt}
                                </Input>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="quality"> Quality </Label>
                                <Input id="quality" bsSize="sm" type="select" value={quality} onChange={(e) => setQuality(e.target.value)} required>
                                    <option value=""> --Select Quality-- </option>
                                    <option value="1"> Full Cream </option>
                                    <option value="2"> Toned </option>
                                    <option value="3"> Double Toned </option>
                                </Input>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="time"> Time </Label>
                                <Input id="time" bsSize="sm" type="select" value={time} onChange={(e) => setTime(e.target.value)} required>
                                    <option value=""> --Select Time-- </option>
                                    {timingOpt}
                                </Input>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="payment"> Payment Mode </Label>
                                <Input id="payment" bsSize="sm" type="select" value={payment} onChange={(e) => setPayment(e.target.value)} required>
                                    <option value=""> --Select Payment Mode-- </option>
                                    {paymentOpt}
                                </Input>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Label for="frequency"> Frequency </Label>
                                <Input id="frequency" bsSize="sm" type="select" value={frequency} onChange={(e) => setFrequency(e.target.value)} required>
                                    <option value=""> --Select Frequency-- </option>
                                    {frequencyOpt}
                                </Input>
                            </Col>
                            <hr />
                            <Col md={12} className="mb-2">
                                <Button color='primary' size="sm" block disabled={loading}> Submit {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>} </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal
                centered
                scrollable
                size="xl"
                isOpen={modalView}
                toggle={() => setModalView(!modalView)}
            >
                <ModalHeader toggle={() => setModalView(!modalView)}>
                    My Product Requirments
                </ModalHeader>
                <ModalBody>
                    <DataTable
                        title=""
                        columns={[
                            {
                                name: 'Product Name',
                                selector: row => row.prod,
                                sortable: true,
                            },
                            {
                                name: 'Requirement/Day',
                                selector: row => row.requirement,
                                sortable: false,
                            },
                            {
                                name: 'Price',
                                selector: row => row.price,
                                sortable: false,
                            },
                            {
                                name: 'Packaging',
                                selector: row => row.packaging,
                                sortable: false,
                                wrap: true,
                            },
                            {
                                name: 'Source',
                                selector: row => row.source,
                                sortable: false,
                            },
                            {
                                name: 'Type',
                                selector: row => row.type,
                                sortable: false,
                            },
                            {
                                name: 'Quality',
                                selector: row => row.quality,
                                sortable: false,
                            },
                            {
                                name: 'Time',
                                selector: row => row.time,
                                sortable: false,
                            },
                            {
                                name: 'Payment',
                                selector: row => row.payment,
                                sortable: false,
                            },
                            {
                                name: 'Frequency of Purchase',
                                selector: row => row.frequency,
                                sortable: false,
                            },

                        ]}
                        data={productList}
                        striped
                        highlightOnHover
                        progressPending={loading}

                    />
                </ModalBody>
            </Modal>

        </>
    )
}

export default ProductRequirements;