import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from './../../hocs/notification';

import axios from 'axios';
import {mainUrl, mapKey} from '../../../MainUrl';
import { Row, Col, Form, Label, Input, Button, Card, CardBody, Spinner, InputGroup, InputGroupText } from 'reactstrap';
import {MdMyLocation} from 'react-icons/md';
import {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,

} from 'react-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByLatLng } from 'react-google-places-autocomplete';

const BuyerRegistration = ({ back, phone, ...props }) => {
    
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [rating, setRating] = useState('0');
    const [noRoom, setNoRoom] = useState('0');
    const [nostaff, setNostaff] = useState('0');

    const [cDesignation, setCDesignation] = useState('');
    const [cName, setCName] = useState('');
    const [mobileNo, setMobileNo] = useState(sessionStorage.getItem('r_mobile_no'));
    const [email, setEmail] = useState('');
    const [education, setEducation] = useState("5");
    const [state, setState] = useState("");
    const [district, setDistrict] = useState("");
    const [pincode, setPincode] = useState("");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");
    const [lat, setLat] = useState("0");
    const [lng, setLng] = useState("0");

    useEffect(()=>{
        get_current_location();
    },[])

    const handleChange = address => {
        setAddress(address);
    };

    const handleSelect = async value => {
        const res = await geocodeByAddress(value);
        const latLng = await getLatLng(res[0]);
        console.log(res);
        setLat(latLng.lat);
        setLng(latLng.lng);

        setAddress(res[0].formatted_address);

        res[0].address_components.map(row => {

            if (row.types.indexOf("administrative_area_level_1") > -1) {
                setState(row.long_name);
            }
            if (row.types.indexOf("administrative_area_level_3") > -1) {
                setDistrict(row.long_name);
            }
            if (row.types.indexOf("locality") > -1) {
                setCity(row.long_name);
            }
            if (row.types.indexOf("postal_code") > -1) {
                setPincode(row.long_name);
            }
        })



    };

    const get_current_location = () => {
        const location = window.navigator && window.navigator.geolocation;
        if (location) {
            location.getCurrentPosition((position) => {
                setLat(position.coords.latitude);
                setLng(position.coords.longitude);
                //get address
                geocodeByLatLng({ lat: position.coords.latitude, lng: position.coords.longitude })
                    .then(res => {
                        setAddress(res[0].formatted_address);

                        res[0].address_components.map(row => {

                            if (row.types.indexOf("administrative_area_level_1") > -1) {
                                setState(row.long_name);
                            }
                            if (row.types.indexOf("administrative_area_level_3") > -1) {
                                setDistrict(row.long_name);
                            }
                            if (row.types.indexOf("locality") > -1) {
                                setCity(row.long_name);
                            }
                            if (row.types.indexOf("postal_code") > -1) {
                                setPincode(row.long_name);
                            }
                        })
                    })
                    .catch(error => console.error(error));
            }, (error) => {
                setLat(0);
                setLng(0);
            });
        }

    }

    const register_handler = () => {
        let flag = true;
        if (name === "") {
            flag = false;
            let notify = notification({ message: "Please add Organization Name. ", type: 'warn' });
            notify();
        }
        if (type === "") {
            flag = false;
            let notify = notification({ message: "Please select organization Type. ", type: 'warn' });
            notify();
        }
        if (cName === "") {
            flag = false;
            let notify = notification({ message: "Please add Contact preson name. ", type: 'warn' });
            notify();
        }
        if (education === "") {
            flag = false;
            let notify = notification({ message: "Please select education. ", type: 'warn' });
            notify();
        }
        if (address === "" || lat === "0" || lng === "0") {
            flag = false;
            let notify = notification({ message: "Please select Address. ", type: 'warn' });
            notify();
        }

        if(flag){

            setLoading(true);
            let postData = [{
                "org_name": name,
                "org_type": type,
                "rating": rating,
                "no_room": noRoom,
                "no_staff": nostaff,
                "name": cName,
                "designation": cDesignation,
                "mobile_no": mobileNo,
                "education": education,
                "email": email,
                "photo": "buyer.png",
                "state": state,
                "district": district,
                "city": city,
                "address": address,
                "pincode": pincode,
                "lat": lat,
                "lng": lng,
                }];
    
            axios({
                method: 'post',
                url: mainUrl + 'user/buyerRegistration',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    if(response.data[0].status === 1){
                        let notify = notification({ message: response.data[0].message, type: 'success' });
                        notify();
                        sessionStorage.setItem('user_detail', JSON.stringify(response.data[0].data));
                        sessionStorage.setItem('user_id', response.data[0].data.buyer_code);
                        sessionStorage.setItem('currId', '11');
                        navigate('/ProductMapBuyer');
                    }else{
                        let notify = notification({ message: response.data[0].message, type: 'error' });
                        notify();
                        setLoading(false);
                    }
    
                }).catch(error => {
                    setLoading(false);
                    let notify = notification({ message: "Failed! Try Again", type: 'error' });
                    notify();
                })
        }
    }

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col xs="12">
                        <div className=''>
                            <h4 className='text-primary mt-2 mb-3'>Buyer Registration</h4>
                        </div>
                        {step === 1 &&
                            <Row>
                                <h6>Basic Information</h6>
                                
                                <Col md="6" className='mb-2'>
                                    <Label for="name">Name of Organization</Label>
                                    <Input id="name" bsSize="sm" type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Organization Name" />
                                </Col>
                                        
                                <Col md="6" className='mb-2'>
                                    <Label for="type">Type of Organization</Label>
                                    <Input id="type" bsSize="sm" type="select" value={type} onChange={(e) => setType(e.target.value)} required>
                                        <option value="0"> --Select Type--</option>
                                        <option value="1"> Hotel </option>
                                        <option value="2"> Restaurant </option>
                                        <option value="3"> Canteen </option>
                                        <option value="4"> Sweet shop </option>
                                        <option value="5"> Dairy Retailer </option>
                                        <option value="6"> Other Retailer </option>
                                        <option value="7"> Other </option>
                                    </Input>
                                </Col>
                                {type === "1" ?
                                    <>
                                        <Col md={6} className="mb-2">
                                            <Label for="rating"> Star Rating of Hotel </Label>
                                            <Input id="rating" bsSize="sm" type="select" value={rating} onChange={(e) => setRating(e.target.value)} required>
                                                <option value="0"> --Select Rating--</option>
                                                <option value="1"> 5 Star </option>
                                                <option value="2"> 4 Star </option>
                                                <option value="3"> 3 Star </option>
                                                <option value="4"> 2 Star </option>
                                                <option value="5"> 1 Star </option>
                                                <option value="6"> No Rating </option>
                                            </Input>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <Label for="noRoom"> Number of Rooms </Label>
                                            <Input id="noRoom" type='text' bsSize="sm" maxLength={3} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={noRoom} onChange={(e) => setNoRoom(e.target.value)} placeholder="No. of Rooms" required />
                                        </Col>
                                    </>
                                    :
                                    <Col md={6} className="mb-2">
                                        <Label for="nostaff"> Number of Staff </Label>
                                        <Input id="nostaff" type='text' bsSize="sm" maxLength={3} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={nostaff} onChange={(e) => setNostaff(e.target.value)} placeholder="No. of Staff" required />
                                    </Col>

                                }
                                <h6>Contact Person Information</h6>
                                <Col md="6" className='mb-2'>
                                    <Label for="cName">Contact Person Name</Label>
                                    <Input id="cName" bsSize="sm" type="text" value={cName} onChange={(e) => setCName(e.target.value)} placeholder="Contact Person Name" />
                                </Col>
                                <Col md="6" className='mb-2'>
                                    <Label for="cDesignation">Contact Person Designation</Label>
                                    <Input id="designation" bsSize="sm" type="select" value={cDesignation} onChange={(e) => setCDesignation(e.target.value)} required>
                                        <option value="0"> --Select Designation--</option>
                                        <option value="3"> Owner </option>
                                        <option value="4"> Manager </option>
                                    </Input>
                                </Col>
                                <Col md={6} className="mb-2">
                                    <Label for="mobileNo"> Mobile Number</Label>
                                    <InputGroup size="sm">
                                        <InputGroupText> +91 </InputGroupText>
                                        <Input id="mobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} placeholder="Your Mobile Number" readOnly />
                                    </InputGroup>
                                    
                                </Col>
                                <Col md={6} className="mb-2">
                                    <Label for="email"> Email </Label>
                                    <Input id="email" type='email' bsSize="sm" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
                                </Col>

                                <h6>Address Information</h6>
                                
                                <Col md={12} className="mb-2">
                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={handleChange}
                                            onSelect={handleSelect}
                                            searchOptions= {{componentRestrictions: { country: "in" },}}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: 'Search your address and select',
                                                            className: 'location-search-input form-control',
                                                            row:"2"
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map(suggestion => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#A7C7EA', cursor: 'pointer', padding: '5px' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '5px' };
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style,
                                                                    })}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </Col>
                                    <Col md={12} className="mb-2">
                                        <span className='text-primary cursor' onClick={get_current_location}>
                                            <MdMyLocation color="#2473cb" size={30} />
                                            <span className='ms-2'><b>Use my current Location</b></span>
                                        </span>
                                    </Col>
                                
                                
                                <Col xs={12} className="d-flex justify-content-between mt-3">
                                    <Button color='secondary' size="sm" onClick={back}>Back</Button>
                                    <Button color='primary' size="sm" onClick={register_handler} disabled={loading}> Register {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
                                    
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default BuyerRegistration;