import React from 'react';
import {
    Row,
    Col,
} from 'reactstrap';

function WIP() {
    return (
        <div>

            <Row className='p-3'>
                <Col xs="12" className='text-center'>
                    <img className='img-fluid' alt="loading..." src={require('./../assets/img/wip.gif')} />
                   <h1>Work in Progress</h1> 
                </Col>
            </Row>
        </div>
    )
}

export default WIP;