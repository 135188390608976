import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, CardBody, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner } from 'reactstrap';
import { notification } from './../../hocs/notification';

import axios from 'axios';
import {mainUrl} from '../../../MainUrl';

import {MdArrowBackIosNew, MdRefresh} from 'react-icons/md';

const BuyerLogin = ({ signup, ...props }) => {
    const navigate = useNavigate();
    const [mode, setMode] = useState(1);
    const [loading, setLoading] = useState(false);
    const [mobileNo, setMobileNo] = useState('');
    const [otp, setOtp] = useState('');

    const varify_otp_handler = (e) => {
        e.preventDefault();
        setLoading(true);

        let postData = {"phoneno": mobileNo, "otp": otp };
        axios({
            method: 'post',
            url: mainUrl + 'user/validateOtp',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data.status === 1){
                    let notify = notification({ message: response.data.message, type: 'success' });
                    if(response.data.data.userDetails.user_type === "2" ){
                        sessionStorage.setItem('user_detail', JSON.stringify(response.data.data.userDetails));
                        sessionStorage.setItem('user_id', response.data.data.userDetails.buyer_code);
                        sessionStorage.setItem('currId', '11');
                        navigate('/productMapBuyer');
                    }else if(response.data.data.userDetails.user_type === "1"){
                        setMode(1);
                        let notify = notification({ message: "You are not using the correct login. Please login as a Farmer.", type: 'error' });
                        notify();
                    }
                }else if(response.data.status === 2){
                    let notify = notification({ message: response.data.message, type: 'warn' });
                    notify();
                    sessionStorage.setItem('r_mobile_no', mobileNo);
                    signup();
                }else{
                    let notify = notification({ message: response.data.message, type: 'error' });
                    notify();
                }
                setLoading(false);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const get_otp_handler = (e) => {
        e.preventDefault();
        setLoading(true);

        let postData = {"phoneno": mobileNo};
        axios({
            method: 'post',
            url: mainUrl + 'user/generateOtp',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data.status === 1){
                    let notify = notification({ message: response.data.message, type: 'success' });
                    setOtp(response.data.data.otp);
                    notify();
                    setMode(2);
                }else{
                    let notify = notification({ message: response.data.message, type: 'error' });
                    notify();
                }
                setLoading(false);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    return (
        <>
            <Card>
                <CardBody>
                    {mode === 1 &&
                        <Form onSubmit={get_otp_handler}>
                            <Row>
                                <Col xs="12" className="mb-4 text-primary text-center"><h3>Welcome Buyer!</h3></Col>
                                <Col xs="12" className="mb-4">
                                    <Label for="mobileNo"> Mobile Number</Label>
                                    <InputGroup>
                                        <InputGroupText> +91 </InputGroupText>
                                        <Input id="mobileNo" type='text' value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} placeholder="Enter your mobile number." bsSize="md" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} required />
                                    </InputGroup>
                                    
                                </Col>
                                <Col xs="12" className="mb-4">
                                    <Button color='primary' size='md' block disabled={loading}>Login {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
                                </Col>
                            </Row>
                        </Form>
                    }

                    {mode === 2 && 
                        <Form onSubmit={varify_otp_handler}>

                            <Row>
                                <Col xs="12" className="mb-4 text-primary text-center"><h3>Verify Buyer!</h3></Col>
                                <Col xs="12" className="mb-4">
                                    <Label for="otp"> OTP </Label>
                                    <Input id="otp" type='text' value={otp} onChange={(e) => setOtp(e.target.value)} placeholder="Enter 6-digits OTP." bsSize="md" maxLength={6} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} required />
                                </Col>
                                <Col xs="12" className="mb-2">
                                    <Button color='primary' size='md' block disabled={loading}>Verify OTP {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
                                </Col>
                                <Col xs="12" className="d-flex justify-content-between">
                                    <div className='cursor backBtn' onClick={()=> setMode(1)}><MdArrowBackIosNew />back</div>
                                    <div className='cursor backBtn' onClick={()=> console.log("resend otp...")}><MdRefresh />Re-send</div>
                                </Col>
                            </Row>
                        </Form>
                    }

                </CardBody>
            </Card>

        </>
    )
}

export default BuyerLogin;