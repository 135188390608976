import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {mainUrl} from '../../../MainUrl';
import { notification } from '../../hocs/notification';
import { Row, Col, Badge, Modal, ModalBody, ModalHeader, Input, Label, Button, Spinner  } from 'reactstrap';

import {FaEdit, FaPlusCircle} from 'react-icons/fa';

const FarmerProfile = ({ data, ...props }) => {
    const [loading, setLoading] = useState(false);
    const [farmerDtl, setFarmerDtl] = useState('');

    const [editModal, setEditModal] = useState(false);
    const [qty, setQty] = useState('');
    const [uom, setUom] = useState('');
    const [prodId, setProdId] = useState('');
    
    const [productOpt, setProductOpt] = useState('');
    const [addModal, setAddModal] = useState(false);
    const [addQty, setAddQty] = useState('');
    const [addUom, setAddUom] = useState('');
    const [addProdId, setAddProdId] = useState('');

    useEffect(()=>{
        get_product_list();
        get_farmer_detail();
    },[])

    const get_farmer_detail = () => {
        let postData = { "farmer_code": data.farmer_code}
        axios({
            method: 'post',
            url: mainUrl + 'activity/getFarmerDetail',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if (response.data.status === 1) {
                    setFarmerDtl(response.data.data.farmer);
                }
            })
            .catch(error => {
                let notify = notification({ message: "Detail not found", type: 'error' });
                notify();
            })
    }

    const get_product_list = () => {
        axios({
            method: 'post',
            url: mainUrl + 'master/getProduct',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let prodlist = response.data.data;

                let list = response.data.data.map((row, index) => {
                    return <option key={index} value={row.prod_id}> {row.prod_name} </option>
                });

                setProductOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "Product list not found", type: 'error' });
                notify();
            })
    }

    const update_handler = () => {
        setLoading(true);
        
        let postData = {
            "user_id": data.farmer_code,
            "prod_id": prodId,
            "uom": uom,
            "qty": qty
        };

        axios({
            method: 'post',
            url: mainUrl + 'activity/editProductQty',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if (response.data.status === 1) {
                    get_farmer_detail();
                    setEditModal(false);
                    let notify = notification({ message: response.data.message, type: 'success' });
                    notify();
                } else {
                    let notify = notification({ message: response.data.message, type: 'error' });
                    notify();
                }
                
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed", type: 'error' });
                notify();
            })
    }

    const add_handler = () => {
        setLoading(true);
        
        let postData = {
            "user_id": data.farmer_code,
            "user_type": 1,
            "prod_id": addProdId,
            "uom": addUom,
            "min_qty": addQty,
            "max_qty": addQty,
            "fpo_code": data.fpo_code,
        };

        axios({
            method: 'post',
            url: mainUrl + 'activity/addNewUserProduct',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if (response.data.status === 1) {
                    get_farmer_detail();
                    setAddModal(false);
                    setAddProdId('');
                    setAddQty('');
                    setAddUom('');
                    let notify = notification({ message: response.data.message, type: 'success' });
                    notify();
                } else {
                    let notify = notification({ message: response.data.message, type: 'error' });
                    notify();
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed", type: 'error' });
                notify();
            })
    }

    return (
        <Row>
            <Col xs={12}>
                <div className='d-flex justify-content-between'>
                    <div><b>Name:</b></div>
                    <div><b>{farmerDtl?.name} ( {farmerDtl?.farmer_code} )</b></div>
                </div>
                <div className='d-flex justify-content-between'>
                    <div><b>Gender:</b></div>
                    <div><b>{data.gender_details}</b></div>
                </div>
                <div className='d-flex justify-content-between'>
                    <div><b>Mobile No:</b></div>
                    <div><b>{farmerDtl?.mobile_no}</b></div>
                </div>
                <div className='d-flex justify-content-between'>
                    <div><b>Education:</b></div>
                    <div><b>{data.education_detail}</b></div>
                </div>
                <div className='d-flex justify-content-between'>
                    <div><b>State:</b></div>
                    <div><b>{data.address_detail.state}</b></div>
                </div>
                <div className='d-flex justify-content-between'>
                    <div><b>District:</b></div>
                    <div><b>{data.address_detail.district}</b></div>
                </div>
                <div className='d-flex justify-content-between'>
                    <div><b>Village:</b></div>
                    <div><b>{data.address_detail.village}</b></div>
                </div>
                <hr />
                <h6>Product Details</h6>
                <Row>
                    {(farmerDtl?.productList?.length > 0) ?
                        farmerDtl?.productList.map((row, index) => {
                            return (
                                <Col className='mb-2' xs={6} md={4} key={index}>
                                    <div className='mb-1 product bg-white position-relative'>
                                        <div className='position-absolute top-0 end-0'><FaEdit size={18} onClick={()=>{
                                            setProdId(row.prod_id);
                                            setQty(row.min_qty);
                                            setUom(row.uom);
                                            setEditModal(true);
                                            }} /></div>
                                        <div className='text-center'>
                                            <img className='img-fluid' width="40px" src={require('../../../assets/img/prod_5.jpg')} alt="img" />
                                            <div> <b>{row.product_name}</b> </div>
                                        </div>
                                        <div className='d-flex justify-content-between fs-12 text-muted'>
                                            <div><b>Qty:</b></div>
                                            <div>{row.min_qty} {row.uom === 1 ? "kg / day" : "Ltrs / day"}</div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                        :
                        <Badge className="me-1" color="primary" pill >No Product Added</Badge>
                    }

                        <Col xs={6} md={4}>
                            <div className="product h-100 w-100 d-flex justify-content-center align-items-center cursor bg-white" onClick={() => setAddModal(true)}>
                                <div className='text-center'>
                                    <FaPlusCircle size={40} color="#2473cb" />
                                    <div>Add New Product</div>
                                </div>
                            </div>
                        </Col>

                </Row>
            </Col>
            <Modal
                centered
                scrollable
                size="md"
                isOpen={editModal}
                toggle={() => setEditModal(!editModal)}
            >
                <ModalHeader toggle={() => setEditModal(!editModal)}>
                    Edit
                </ModalHeader>
                <ModalBody className='bg-light'>
                    <Row>
                        <Col xs={6} className="mb-2">
                            <Label for="qty"> Quantity </Label>
                            <Input id="qty" bsSize="sm" type="number" value={qty} onChange={(e)=> setQty(e.target.value)} required />
                        </Col>
                        <Col xs={6} className="mb-2">
                            <Label for="uom"> Uniy / Day </Label>
                            <Input id="uom" bsSize="sm" type="select" value={uom} onChange={(e)=> setUom(e.target.value)} required>
                                <option value=""> --Select Qty/Day-- </option>
                                <option value="2"> Liter </option>
                                <option value="1"> Kg </option>
                            </Input>
                        </Col>
                        <Col xs={12} className="mb-2 text-end">
                            <Button color='primary' size="sm" onClick={update_handler} disabled= {loading}> Update {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>} </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal
                centered
                scrollable
                size="md"
                isOpen={addModal}
                toggle={() => setAddModal(!addModal)}
            >
                <ModalHeader toggle={() => setAddModal(!addModal)}>
                    Add Product
                </ModalHeader>
                <ModalBody className='bg-light'>
                    <Row>
                        <Col xs={12} className="mb-2">
                            <Label for="uom"> Product </Label>
                            <Input id="uom" bsSize="sm" type="select" value={addProdId} onChange={(e)=> setAddProdId(e.target.value)} required>
                                <option value=""> --Select Product-- </option>
                                {productOpt}
                            </Input>
                        </Col>
                        <Col xs={6} className="mb-2">
                            <Label for="qty"> Quantity </Label>
                            <Input id="qty" bsSize="sm" type="number" value={addQty} onChange={(e)=> setAddQty(e.target.value)} required />
                        </Col>
                        <Col xs={6} className="mb-2">
                            <Label for="uom"> Uniy / Day </Label>
                            <Input id="uom" bsSize="sm" type="select" value={addUom} onChange={(e)=> setAddUom(e.target.value)} required>
                                <option value=""> --Select Qty/Day-- </option>
                                <option value="2"> Liter </option>
                                <option value="1"> Kg </option>
                            </Input>
                        </Col>
                        <Col xs={12} className="mb-2 text-end">
                            <Button color='primary' size="sm" onClick={add_handler} disabled= {loading}> Add {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>} </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Row>
    )

}

export default FarmerProfile;